import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyAfgXDpwaTH9ha1jnJKKp1KsLmgEXod_Aw",
    authDomain: "uma-chhatra.firebaseapp.com",
    projectId: "uma-chhatra",
    storageBucket: "uma-chhatra.appspot.com",
    messagingSenderId: "693604401942",
    appId: "1:693604401942:web:6fda61b99b74d45e7ec637",
    measurementId: "G-JY16YRQPBQ"
}

const app = initializeApp(config);

export const auth = getAuth(app);