import React from "react";
import API from '../../apiAuth';
import DatatableTables from "components/Common/Tables/DatatableTables";
import SweetAlert from "react-bootstrap-sweetalert"
import ToastMessage from 'components/Common/toastar';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, FormGroup, Button, CardTitle, CardSubtitle, Label, Input, Container, CustomInput, InputGroup, Form, Modal } from "reactstrap";
import Lightbox from "react-image-lightbox"
import img1 from "../../assets/images/maintenance.png"
import ImageView from "components/Common/imageView";
import PaymentView from "components/Common/paymentView";
// import MemberPDF from "./memberPDF";
// import ModalPDF from "./memberPDFView";
import { decodeRole } from "common/decodedRole";
import { VOLUNTEER_ROLE } from "common/roles";
import "react-image-lightbox/style.css"
import logosmImg from "../../assets/images/umachattra-logo.png";

export class ViewMember extends React.Component {
    role;
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            singleMember: null,
            singleMemberId: props.memberID,
            isFits: false,
            currentAttachment: null,
            isVeified: false,
            confirm_alert: false,
            formDataUploads: {},
            imageNotFound: img1,
            errored: false
        }
    }

    componentDidMount() {
        this.getSingleMemberDetail();
        this.role = decodeRole();
    }

    showNotFoundMessage(type) {
        return <div className="not-found-message">{type} Data Not Available</div>
    }

    getSingleMemberDetail() {
        let data = { member_id: this.state.singleMemberId }
        API.post(process.env.REACT_APP_APIURLUSER + '/single_member', data).then(res => {
            if (res.data.status === 1) {
                let bank = res.data.data.bank_nach && res.data.data.bank_nach.length ? res.data.data.bank_nach[0] : {}
                let data = {
                    ...bank,
                    ...res.data.data.member,
                    payment_data: res.data.data.payment
                }
                this.setState(prevState => ({
                    ...prevState, singleMember: data,
                    formDataUploads: {
                        user_images: data.user_images,
                        user_aadhar_cad: data.user_aadhar_cad,
                        user_pan_card: data.user_pan_card,
                        user_cheque: data.user_cheque,
                        user_signechar: data.user_signechar,
                    }
                }))
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    verifyConfirmation() {
        this.setState(prevState => ({ ...prevState, confirm_alert: true }))
    }

    verifyMember() {
        let data = { member_id: this.state.singleMemberId }
        API.post(process.env.REACT_APP_APIURLUSER + '/verified_member', data).then(res => {
            if (res.data.status === 1) {
                let data = res.data.data;
                ToastMessage({ type: 'success', message: res.data.message })
                this.setState(prevState => ({ ...prevState, confirm_alert: false, singleMember: { ...prevState.singleMember, status_verified: true } }))
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    assignPhotostate(imageUrl) {
        this.setState(prevState => ({ ...prevState, currentAttachment: imageUrl, isFits: true }))
    }

    removePhotostate() {
        this.setState(prevState => ({ ...prevState, isFits: false, currentAttachment: null }))
    }

    getMemberCertificate(member_id) {
        let data = {
            member_id: member_id
        }
        API.post(process.env.REACT_APP_APIURLUSER + '/member_certificate', data).then(res => {
            if (res.data.status === 0) {
                this.setState(prevState => ({ ...prevState, isMemberCertificateShow: true, isMemberCertificateContent: res.data.data }))
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    hideModal() {
        this.setState(prevState => ({ ...prevState, isMemberCertificateShow: false, isMemberCertificateContent: null }))
    }

    Print() {
        var divContents = document.getElementById("printablediv").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write(divContents);
        a.document.close();
        a.print();
    }

    render() {
        return (
            <React.Fragment>
                {this.state.confirm_alert ? (
                    <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, Verify it!" confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => { this.verifyMember() }}
                        onCancel={() => this.setState(prevState => ({ ...prevState, confirm_alert: false }))
                        }>You won't be able to revert this!</SweetAlert>
                ) : null}
                {this.state.isFits ? (
                    <Lightbox
                        mainSrc={this.state.currentAttachment}
                        enableZoom={false}
                        onCloseRequest={() => { this.removePhotostate() }} />
                ) : null}
                <Row >
                    <Modal
                        size="lg"
                        isOpen={this.state.isMemberCertificateShow}
                    >
                        <div className="modal-header">
                            <h5
                                className="modal-title mt-0"
                                id="myExtraLargeModalLabel"
                            >
                                Member Certificate
                            </h5>
                            <button onClick={() => { this.hideModal() }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="printablediv">
                                <div dangerouslySetInnerHTML={{ __html: this.state.isMemberCertificateContent }} />
                            </div>
                            <Button type="button" className="text-right print-btn" color="primary" onClick={() => { this.Print() }}>Print</Button>

                        </div>
                    </Modal>
                    <Col lg={12}>
                        {this.state.singleMember ?
                            <div>
                                <form id="parentdiv">
                                    <div className="margin-member d-flex align-items-baseline">
                                        <Link to="/users/member" className="logo text-center">
                                            <Button type="button" color="warning">Back</Button>
                                        </Link>
                                        {this.state.singleMember.status_verified ?
                                            <div className="ml-4">
                                                <Button type="button" color="primary" onClick={() => this.getMemberCertificate(this.state.singleMember.member_id)} className="">Member Certificate</Button>
                                            </div> : ""}

                                    </div>
                                    <div className="head-text mb-2"> Personal Information </div>

                                    <div className="row row-container-view mb-3">
                                        <div className="col-md-4">


                                            <div className="info-container col-8 mt-4">
                                                {this.state.formDataUploads && this.state.formDataUploads.user_images ?
                                                    <img id="userimage" onClick={() => { this.assignPhotostate(this.state.formDataUploads.user_images) }} className="img-fluid uploads" alt="User-Image" src={this.state.formDataUploads.user_images} />
                                                    : <img id="userimage" onClick={() => { this.assignPhotostate(logosmImg) }}
                                                        className="img-fluid uploads" alt="User-Image" src={logosmImg} />}

                                            </div>
                                        </div>

                                        <div className="col-md-4">

                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Full Name : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.name} {this.state.singleMember.father_name}  {this.state.singleMember.surname_name}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Grand Father Name : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.grandfather_name}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Birth Date : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.birth_date}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Email : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.email}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Mobile number 1 : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.mobile_no_1}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Mobile number 2 : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.mobile_no_2}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Blood Group : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.blood_group}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Cast : </label>
                                                <div className="view-form-content  col-7">{this.state.singleMember.cast}</div>
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Gender : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.gender}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">PAN Card : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.pan_card_no}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Aadhar Card : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.aadhar_cad_no ? this.state.singleMember.aadhar_cad_no : '-'}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Marital Status : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.marital_status}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Birth Place : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.birth_place}</div>
                                            </div>

                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Native Place : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.native_place}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Past Health Problems : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.past_health_problems}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label  col-5">Samaj : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.samaj}</div>
                                            </div>

                                            <div className="info-container col-12">
                                                <label className="control-label  col-5">Other Cast Name : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.other_cast_name}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="head-text mb-2"> Contribution - Address & Qualification</div>

                                    <div className="row row-container-view mb-3">
                                        <div className="col-md-4">
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Contribution Name : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.plan_info.plan_name}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Amount : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.plan_info.plans_amount}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Contribution Type : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.plan_info.plans_payment_type}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Max Age : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.plan_info.plans_age_max}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Approx Year : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.plan_info.approx_year_payment}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Valid Age : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.plan_info.plans_validity_age}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Min Age : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.plan_info.plans_age_min}</div>
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Current Address : </label>
                                                <div className="view-form-content  col-7">{this.state.singleMember.current_address}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label  col-5">State : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.state}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">District : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.district}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">City : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.city}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Village : </label>
                                                <div className="view-form-content  col-7">{this.state.singleMember.village}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label  col-5">Pin Code : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.pin_code}</div>
                                            </div>


                                        </div>
                                        <div className="col-md-4">
                                            <div className="info-container col-12">
                                                <label className="control-label  col-5">Education : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.education}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label  col-5">Occupation : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.occupation}</div>
                                            </div>

                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Other Occupation : </label>
                                                <div className="view-form-content  col-7">{this.state.singleMember.other_occupation}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Work Address : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.work_address}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Work Address Pincode : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.work_pin_code}</div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="head-text mb-2"> Family Information </div>


                                    <table className="table table-responsive table-striped">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Mobile Number</th>
                                                <th>Relation</th>
                                                <th>Age</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.singleMember.family_info && this.state.singleMember.family_info.member.length ?
                                                this.state.singleMember.family_info.member.map((res, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{res.name}</td>
                                                            <td>{res.mobile_no}</td>
                                                            <td>{res.relation}</td>
                                                            <td>{res.age}</td>
                                                        </tr>
                                                    )
                                                })
                                                : this.showNotFoundMessage('Family')}
                                            <tr>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <div className="head-text mb-2"> Witness - Donor & Reference Information </div>

                                    <div className="row row-container-view mb-3">
                                        <div className="col-md-4">
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Witness Name : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.witness_name}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Witness Mobile Number : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.witness_mobile_no}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Vuf Witness Name : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.vuf_witness_name}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Vuf Witness Mobile Number : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.vuf_witness_mobile_no}</div>
                                            </div>
                                        </div>


                                        <div className="col-md-4">
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Donor Name : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.donor_name}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Donor Mobile Number : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.donor_mobile_no}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Donor Address : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.donor_address}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Donor Pin Code : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.donor_pin_code}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Referencing Given Name : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.referencing_given_name}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Referencing Address : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.rf_address}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Referencing Mobile Number : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.rf_mobile_no}</div>
                                            </div>
                                            <div className="info-container col-12">
                                                <label className="control-label col-5">Referencing Pin Code : </label>
                                                <div className="view-form-content col-7">{this.state.singleMember.rf_pin_code}</div>
                                            </div>
                                        </div>
                                    </div>


                                    {this.state.singleMember.account_number ?
                                        <div>
                                            <div className="head-text mb-2"> ACH/Direct Debit Information </div>
                                            <div className="row row-container-view mb-3">
                                                <div className="col-md-6">
                                                    <div className="info-container col-12">
                                                        <label className="control-label col-5">Account Number : </label>
                                                        <div className="view-form-content col-7">{this.state.singleMember.account_number}</div>
                                                    </div>
                                                    <div className="info-container col-12">
                                                        <label className="control-label col-5">Bank Name : </label>
                                                        <div className="view-form-content col-7">{this.state.singleMember.bank_name}</div>
                                                    </div>
                                                    <div className="info-container col-12">
                                                        <label className="control-label col-5">Bank Cheque Number : </label>
                                                        <div className="view-form-content col-7">{this.state.singleMember.bank_cheque_no}</div>
                                                    </div>
                                                    <div className="info-container col-12">
                                                        <label className="control-label col-5">Bank Holder Name : </label>
                                                        <div className="view-form-content col-7">{this.state.singleMember.bank_holder_name}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="info-container col-12">
                                                        <label className="control-label col-5">Contribution Type : </label>
                                                        <div className="view-form-content col-7">{this.state.singleMember.payment_type}</div>
                                                    </div>
                                                    <div className="info-container col-12">
                                                        <label className="control-label col-5">Frequency : </label>
                                                        <div className="view-form-content col-7">{this.state.singleMember.frequency}</div>
                                                    </div>
                                                    <div className="info-container col-12">
                                                        <label className="control-label col-5">IFSC Number : </label>
                                                        <div className="view-form-content col-7">{this.state.singleMember.ifsc}</div>
                                                    </div>
                                                    <div className="info-container col-12">
                                                        <label className="control-label col-5">MICR Numbe : </label>
                                                        <div className="view-form-content col-7">{this.state.singleMember.micr}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                    {/* this.showNotFoundMessage('NACH/Direct Debit') */}
                                    {this.state.singleMember.payment_data && this.state.singleMember.payment_data.length ?
                                        <PaymentView users={this.state.singleMember} payment={this.state.singleMember.payment_data} />
                                        : ""}
                                    {/* this.showNotFoundMessage('Payment') */}
                                    {this.state.formDataUploads && (this.state.formDataUploads.user_images
                                        || this.state.formDataUploads.user_images || this.state.formDataUploads.user_images || this.state.formDataUploads.user_images
                                        || this.state.formDataUploads.user_images)
                                        ?
                                        <div>
                                            <div className="head-text mb-4"> Attachments </div>
                                            <ImageView formDataUploads={this.state.formDataUploads} viewForm={'view'} />
                                        </div>
                                        : this.showNotFoundMessage('Attachments')}

                                </form>
                            </div> : ""}
                    </Col>
                </Row >
            </React.Fragment>
        )
    }
}


export default ViewMember;