import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"

import { connect } from "react-redux"
import { Form, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Input, Button, Col } from "reactstrap"

import { Link } from "react-router-dom"

// Import menuDropdown
import LanguageDropdown from "./LanguageDropdown"
import logodarkImg from "../../assets/images/umachattra-logo.png";
import logosmImg from "../../assets/images/umachattra-logo.png";
import logolightImg from "../../assets/images/umachattra-logo.png";
import visvUmiyaFoundationLogo from "../../assets/images/visv-umiya-foundation.png";

import { NavLink } from 'react-router-dom';

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
} from "../../store/actions"

const AuthHeader = props => {
    const [search, setsearch] = useState(false)
    const [singlebtn, setSinglebtn] = useState(false)
    const [active, setActive] = useState(false)
    const [menu, setMenu] = useState(false)

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

    const toggle = () => {
        setMenu(!menu)
    }

    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                )
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }

    useEffect(() => {
        console.log(props);
    })

    function getName() {
        return localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name')
    }

    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 992) {
            body.classList.toggle("sidebar-enable");
        } else {
            body.classList.toggle("vertical-collpsed");
            body.classList.toggle("sidebar-enable");
        }
    }

    return (

        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-content user-nav">
                    <div className="d-flex nav-content-link">
                        <div className="row nav-top">
                            <div className="col-md-7 d-flex justify-content-center nav-menu-align">
                                <div className="navbar-external-link-content">{props.t('Matrimonial Portal')}</div>
                                <span className="menu-sepr"> | </span>
                                <div className="navbar-language-link-content">{props.t('Donate Now')}</div>
                            </div>
                            <div className="col-md-3 d-flex language-section">
                                <LanguageDropdown />
                            </div>
                            <div className="col-md-2 d-flex auth-section">
                                <Dropdown isOpen={menu} toggle={toggle} className="d-md-block ms-2">
                                    <DropdownToggle className="btn dropdown-btn" tag="button">
                                        {" "}{getName()}
                                        {" "}<span className="mdi mdi-chevron-down"></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="language-switch dropdown-menu-end">
                                        <DropdownItem>
                                            <div className="navbar-link-logout">
                                                <Link to="/users/logout">{props.t('Logout')}</Link>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="nav-menu-second">
                    <div className="nav-top">
                        <div className="logo-outer-container">
                            <div className="logo-inner-wrapper">
                                <img src={logosmImg} alt="UmaChattra-logo" height="120" />
                                <img src={visvUmiyaFoundationLogo} alt="viv-umiya-foundation-logo" height="120" />
                            </div>
                        </div>
                        <div className="col-12 container d-flex-wrap menu-container justify-content-flex-start">
                            <NavLink className="menu-item removeActive" to="/users/home" isActive={(match, location) => {
                                if (location.pathname === '/users/home') { setActive(true) } else { setActive(false) }
                                return match;
                            }}> <i className={active ? "ti-home active" : "ti-home"}></i></NavLink>
                            <NavLink className="menu-item" activeClassName="active" to="/users/member"> {props.t("Member")} </NavLink>
                            <a className="menu-item"> {props.t("UmaChattra Plan")} </a>
                            <NavLink className="menu-item" activeClassName="active" to="/users/contactus"> {props.t("Contact Us")} </NavLink>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

AuthHeader.propTypes = {
    changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
    const {
        layoutType,
        showRightSidebar,
        leftMenu,
        leftSideBarType,
    } = state.Layout
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
})(withTranslation()(AuthHeader))
