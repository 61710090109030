import React, { useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Container, CardImg } from "reactstrap";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types"
import vishvUmiya from "../../assets/images/umachattra-logo.png"
import sideBanner from "../../assets/images/side-banner.png"
import brochure from "../../assets/images/project_broucher.png"
import ecsPDF from "../../assets/images/project_ecs.png"
import pdf from "../../assets/images/project_pdf.png"
import visvProject from "../../assets/images/visv-umiya-foundation.png"
import matrimonial from "../../assets/images/project_matrimoni.jpg"
import events from "../../assets/images/project_event.jpg"

const Program = props => {



    return (

        <Container className="home-section section-2">
            <div className="project-title-container">
                <div className="project-title-inner">
                    <h1 className="heading-1">{props.t('Projects')}</h1>
                </div>
            </div>

            <Row>
                <div className="col-md-4 col-lg-4 col-xl-4">
                    <Card className="project-custom-card">
                        <div className="content-section2-navigation-card">
                            <a href="https://umachhatra.org/assets/front/assets/img/0BoucherUmachatr.pdf" target="_blank">
                                <div className="content-overlay"></div>
                                <img className="content-image" src={brochure} alt="Brochure" />
                                <div className="content-details fadeIn-bottom">
                                    <div className="content-title">{props.t('VISIT URL')}</div>
                                </div>
                            </a>
                        </div>
                        <CardBody>
                            <div className="card-text text-center">{props.t('Brochure')}</div>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-4 col-lg-4 col-xl-4">
                    <Card className="project-custom-card">
                        <div className="content-section2-navigation-card">
                            <a href="https://umachhatra.org/assets/front/assets/img/0ACHMehsanaUrbanBank.pdf" target="_blank">
                                <div className="content-overlay"></div>
                                <img className="content-image" src={ecsPDF} alt="ECS Form" />
                                <div className="content-details fadeIn-bottom">
                                    <div className="content-title">{props.t('VISIT URL')}</div>
                                </div>
                            </a>
                        </div>
                        <CardBody>
                            <div className="card-text text-center">{props.t('ECS Form')}</div>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-4 col-lg-4 col-xl-4">
                    <Card className="project-custom-card">
                        <div className="content-section2-navigation-card">
                            <a href="https://umachhatra.org/assets/front/assets/img/0ApplyFormUmachatra.pdf" target="_blank">
                                <div className="content-overlay"></div>
                                <img className="content-image" src={pdf} alt="uma-chattra-form" />
                                <div className="content-details fadeIn-bottom">
                                    <div className="content-title">{props.t('VISIT URL')}</div>
                                </div>
                            </a>
                        </div>
                        <CardBody>
                            <div className="card-text text-center">{props.t('Uma Chhatra Form')}</div>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-4 col-lg-4 col-xl-4">
                    <Card className="project-custom-card">
                        <div className="content-section2-navigation-card">
                            <a href="https://vishvumiyafoundation.org/" target="_blank">
                                <div className="content-overlay"></div>
                                <img className="content-image" src={visvProject} alt="visv-umiya-foundation" />
                                <div className="content-details fadeIn-bottom">
                                    <div className="content-title">{props.t('VISIT URL')}</div>
                                </div>
                            </a>
                        </div>
                        <CardBody>
                            <div className="card-text text-center">{props.t('Visv Umiya Foundation')}</div>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-4 col-lg-4 col-xl-4">
                    <Card className="project-custom-card">
                        <div className="content-section2-navigation-card">
                            <a href="https://vivah.vishvumiyafoundation.org//" target="_blank">
                                <div className="content-overlay"></div>
                                <img className="content-image" src={matrimonial} alt="matrimonial" />
                                <div className="content-details fadeIn-bottom">
                                    <div className="content-title">{props.t('VISIT URL')}</div>
                                </div>
                            </a>
                        </div>
                        <CardBody>
                            <div className="card-text text-center">{props.t('Matrimonial')}</div>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-4 col-lg-4 col-xl-4">
                    <Card className="project-custom-card">
                        <div className="content-section2-navigation-card">
                            <a href="https://vishvumiyafoundation.org/events/" target="_blank">
                                <div className="content-overlay"></div>
                                <img className="content-image" src={events} alt="events" />
                                <div className="content-details fadeIn-bottom">
                                    <div className="content-title">{props.t('VISIT URL')}</div>
                                </div>
                            </a>
                        </div>
                        <CardBody>
                            <div className="card-text text-center">{props.t('Events')}</div>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    )

}

Program.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(Program))
