import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import APICommon from '../../apiAuth';

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    CustomInput,
    InputGroup,
    Form
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import { useHistory, useParams } from "react-router-dom";

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from 'axios';

import { PaymentType, BloodGroup, Gender, MaritalStatus, Cast, RelationList, OccupationType, ContributionType } from "common/list";
import ToastMessage from "components/Common/toastar";
import Lightbox from "react-image-lightbox"
import SweetAlert from "react-bootstrap-sweetalert"
import ImageView from "components/Common/imageView";
import PaymentView from "components/Common/paymentView";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { MemberList } from "./memberList";
import AddMember from "./addMember";
import AddPayment from "./addPayment";
import AddDivangat from "./addDivangat";

const optionGroup2 = PaymentType
let state_id;
let isChecked = false;
const MemberOuter = props => {


    let [currentSelectedTab, setCurrentSelectedTab] = useState('Member List')


    const changeActiveTab = event => {
        setCurrentSelectedTab(event)
    }


    return (
        <React.Fragment>
            <Container className="contact-page-container" fluid={false}>
                <Card>
                    <div className="contact-title-container">
                        <Row>
                            <Col lg="12">
                                <div className="contact-us-title-container">
                                    <h3 className="text-white">{props.t('Member Dashboard')}</h3>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="row internal-tab-switch-container">
                        <div className={"col-md-3 text-center internal-link-switch " + (currentSelectedTab == 'Member List' ? 'active' : '')} id="memberList" onClick={() => changeActiveTab('Member List')}>{props.t('Member List')}   </div>
                        <div className={"col-md-3 text-center internal-link-switch " + (currentSelectedTab == 'Add Member' ? 'active' : '')} id="addMember" onClick={() => changeActiveTab('Add Member')}>{props.t('Add Member')}</div>
                        <div className={"col-md-3 text-center internal-link-switch " + (currentSelectedTab == 'Add Divangat' ? 'active' : '')} id="addDivangat" onClick={() => changeActiveTab('Add Divangat')}>{props.t('Add Divangat')}</div>
                        <div className={"col-md-3 text-center internal-link-switch " + (currentSelectedTab == 'Add Payment' ? 'active' : '')} id="addPayment" onClick={() => changeActiveTab('Add Payment')} >{props.t('Add Payment')}</div>
                    </div>
                    <CardBody className="contact-form-container">
                        {currentSelectedTab == 'Member List' ?
                            <MemberList /> :
                            currentSelectedTab == 'Add Member' ? <AddMember /> :
                                currentSelectedTab == 'Add Payment' ? <AddPayment /> :
                                    currentSelectedTab == 'Add Divangat' ? <AddDivangat /> :
                                        <MemberList />}
                    </CardBody>
                </Card>
            </Container>
        </React.Fragment >
    )
}


MemberOuter.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(MemberOuter))

