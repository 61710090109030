import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, CardTitle, Container, CardImg } from "reactstrap";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types"
import vishvUmiya from "../../assets/images/umachattra-logo.png"
import sideBanner from "../../assets/images/side-banner.png"
import brochure from "../../assets/images/project_broucher.png"
import ecsPDF from "../../assets/images/project_ecs.png"
import pdf from "../../assets/images/project_pdf.png"
import visvProject from "../../assets/images/visv-umiya-foundation.png"
import matrimonial from "../../assets/images/project_matrimoni.jpg"
import events from "../../assets/images/project_event.jpg"
import Program from "pages/Home/program";
import Slidewithfade from "../Home/CarouselTypes/slidewithfade"

const UserHome = props => {

    useEffect(() => {
        console.log(props)
        if (!localStorage.getItem("I18N_LANGUAGE"))
            localStorage.setItem("I18N_LANGUAGE", 'eng')
    })


    return (
        <div>
            <React.Fragment>
                <MetaTags>
                    <title>User Home</title>
                </MetaTags>
                <Row>
                    <Col lg="12">
                        <Slidewithfade />
                    </Col>
                </Row>
                <div className="container-fluid">
                    <Container className="home-section section-1">
                        <Row>
                            <Col lg="6">
                                <div className="title-container">
                                    <h4>{props.t('WELCOME')}</h4>
                                    <h3>{props.t('VISHV UMIYA FOUNDATION')}</h3>
                                </div>
                                <div className="text-left-container">
                                    <p>{props.t('ABOUT US Content')}</p>
                                </div>

                                <div className="btn-container">
                                    <button className="btn btn-danger w-md waves-effect waves-light">{props.t('Read More...')}</button>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="img-container-card">
                                    <img src={sideBanner} className="side-img-visv" alt="side-banner" />
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Program />


                </div>

            </React.Fragment>
        </div >
    )

}

UserHome.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(UserHome))
