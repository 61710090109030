import React, { useState, useEffect } from "react"
import axios from 'axios';
import { useHistory } from "react-router-dom";
import ToastMessage from 'components/Common/toastar';
import { logoutUser } from 'store/actions';


var axiosInstanceUmiyaFoundation = axios.create({
    baseURL: process.env.REACT_APP_APIURLUMIYAFOUND,
    headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('auth-token') ? localStorage.getItem('auth-token') : undefined
    }
});

axiosInstanceUmiyaFoundation.interceptors.request.use((config) => {
    return {
        ...config,
        headers: {
            'Content-Type': 'application/json',
            'auth-token': localStorage.getItem('auth-token') ? localStorage.getItem('auth-token') : undefined
        },
        baseURL: process.env.REACT_APP_APIURLUMIYAFOUND
    }
})
// export const store = configureStore()
//This allows you to intercept the response and check the status and error messages and if ncessary reject the promise.
axiosInstanceUmiyaFoundation.interceptors.response.use((response, props) => {
    return response
})

export default axiosInstanceUmiyaFoundation
