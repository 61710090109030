import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
import { VOLUNTEER_ROLE } from "common/roles";

function* loginUser(user, history) {
  const { status, message, data } = user.payload.user;
  history = user.payload.history;
  try {
    if (status == 0) {
      yield put(apiError(message));
    } else {
      localStorage.setItem('acccount_type', data.accountType);
      localStorage.setItem('auth-token', data.auth);
      localStorage.setItem('email', data.email);
      localStorage.setItem('first_name', data.firstName);
      localStorage.setItem('last_name', data.lastName);
      localStorage.setItem('user_id', data.userId);
      localStorage.setItem('user_id', data.userId);
      localStorage.setItem('user_token', window.btoa(data.user));
      if (data.user == VOLUNTEER_ROLE) {
        localStorage.setItem('volunteer_id', data.volunteer_id);
      }
      history.push("/users/user-home")
      yield put(loginSuccess(user.payload));
    }
  } catch (error) {
    yield put(apiError(message));
  }
}

function* logoutUser(history) {
  history = history.payload.history;
  try {
    localStorage.clear()
    yield put(logoutUserSuccess(''))
    history.push("/users/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
