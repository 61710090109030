import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoSm from "../../assets/images/umachattra-logo.png";
import API from '../../apiAuth';
import APIUmiyaFoundation from '../../apiUmiyaFoundationConnect';
import toastr from 'toastr'
import ToastMessage from 'components/Common/toastar';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../../firebaseconfig';
import { countries } from './country.js'
const Login = props => {
  // handleValidSubmit

  let [loginUI, setLoginUI] = useState('Login');
  let [verifiedOtp, setVerifiedOtp] = useState(false)
  let [mynumber, setnumber] = useState("");
  let [loader, setLoader] = useState(false);

  let [mynumberCountry, setnumberCountry] = useState("");

  let [otp, setotp] = useState('');
  let [final, setfinal] = useState('');
  let [finalRegister, setfinalRegister] = useState('');
  let [register_otp, setRegisterOtp] = useState('');
  let [registerOtpVerifiedInputShow, setRegisterOtpVerifiedIputShow] = useState(false);
  let [registerData, setRegisterData] = useState({
    type: "",
    fullName: ''
  })


  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        setfinal('')
      }
    });
  }

  const generateRecaptchaRegister = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha_register', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        setfinalRegister('')
      }
    });
  }

  const handleValidSubmit = (event, values) => {
    // validate on global authentication url
    setLoader(true);
    let data = {
      "mobileNo": values.mobile,
      "countryCode": values.login_country_code,
      "client": process.env.REACT_APP_AUTHNAME,
      "client_secret": 'fw!@y55uma$o8'
    }
    APIUmiyaFoundation.post('check-mobile-registered', data).then(res => {
      if (res.status) {
        if (!final) {
          generateRecaptcha();
        }
        let appVerifier = window.recaptchaVerifier;
        let prefix = '+91' + values.mobile;
        signInWithPhoneNumber(auth, prefix, appVerifier)
          .then((confirmationResult) => {
            setLoader(false);
            setnumber(values.mobile);
            setfinal(confirmationResult);
            setVerifiedOtp(true);
          }).catch((error) => {
            // Error; SMS not sent
            console.log(error);
          });
      } else {
        ToastMessage({ type: 'error', message: 'Authentication Failed' })
      }
    }).catch(error => {
      setLoader(false);
      ToastMessage({ type: 'error', message: 'Authentication Failed' })
    });
  }

  // Validate OTP
  const handleValidSubmitOtp = (event, values) => {
    otp = values.otp;
    if (otp === null || final === null)
      return;
    setLoader(true);
    final.confirm(otp).then((result) => {
      // success
      setLoader(false);
      console.log(result);
      let accessToken = result.user.accessToken;
      let uid = result.user.uid;
      let data = {
        "mobileNo": mynumber,
        "countryCode": "+91",
        "client": process.env.REACT_APP_AUTHNAME,
        "client_secret": 'fw!@y55uma$o8'
      }
      APIUmiyaFoundation.post('login-with-mobile', data).then(res => {
        if (res.data && res.data.status) {
          let usersData = res.data;
          if (usersData.response && usersData.response.User) {
            let data = {
              mobileNo: usersData.response.User.mobileNo,
              countryCode: usersData.response.User.countryCode,
              email: usersData.response.User.email,
              firstName: usersData.response.User.firstName,
              lastName: usersData.response.User.lastName,
              vufId: usersData.response.User.createdAt,
              token: usersData.response.token
            }
            API.post('users/register_user_check', data).then(res => {
              if (res.data.status === 1) {
                API.defaults.headers['auth-token'] = res.data.data.auth;
                ToastMessage({ type: 'success', message: res.data.message })
                props.loginUser(res.data, props.history)
              }
            }).catch(error => {
              ToastMessage({ type: 'error', message: error.message })
            });
          } else {
            ToastMessage({ type: 'error', message: 'User Information is missing' })
          }
        } else {
          ToastMessage({ type: 'error', message: 'Authentication Failed' })
        }
      }).catch(error => {
        ToastMessage({ type: 'error', message: error.message })
      });
    }).catch((err) => {
      setLoader(false);
      ToastMessage({ type: 'error', message: 'Invalid Otp Code' });
    })
  }

  const handleRegisterSubmit = (event, values) => {
    if (!finalRegister) {
      generateRecaptchaRegister();
    }
    setLoader(true);
    let appVerifier = window.recaptchaVerifier;
    let prefix = '+91' + values.mobileNo;
    signInWithPhoneNumber(auth, prefix, appVerifier)
      .then((confirmationResult) => {
        setLoader(false);
        setfinalRegister(confirmationResult);
        setRegisterData(values);
        setRegisterOtpVerifiedIputShow(true);
      }).catch((error) => {
        setLoader(false);
        ToastMessage({ type: 'error', message: error })
      });

  }



  function submitRegisterAfterOtpVerification() {
    if (register_otp === null || finalRegister === null)
      return;

    setLoader(true);
    finalRegister.confirm(register_otp).then((result) => {
      // success
      let accessToken = result.user.accessToken;
      let uid = result.user.uid;
      let data = {
        "mobileNo": registerData.mobileNo,
        "countryCode": registerData.register_country_code,
        "firstName": registerData.firstName,
        "email": registerData.email,
        "lastName": registerData.lastName,
        "accountType": registerData.accountType,
        "mobileVerificationFlag": true,
        "client": process.env.REACT_APP_AUTHNAME,
        "client_secret": 'fw!@y55uma$o8',
        "firebaseToken": accessToken
      }
      APIUmiyaFoundation.post('user', data).then(res => {
        if (res.data && res.data.status) {
          setRegisterData('');
          setfinalRegister('');
          setRegisterOtpVerifiedIputShow(false);
          let login_data = {
            "mobileNo": res.data.response.user.mobileNo,
            "countryCode": res.data.response.user.countryCode,
            "email": res.data.response.user.email,
            "firstName": res.data.response.user.firstName,
            "lastName": res.data.response.user.lastName,
            "vufId": res.data.response.user.vufId,
            "token": res.data.response.token
          }
          API.post('users/register_user_check', login_data).then(res => {
            setLoader(false);
            if (res.data.status === 1) {
              API.defaults.headers['auth-token'] = res.data.data.auth;
              ToastMessage({ type: 'success', message: res.data.message })
              props.loginUser(res.data, props.history)
            }
          }).catch(error => {
            setLoader(false);
            ToastMessage({ type: 'error', message: error.message })
          });
        } else {
          ToastMessage({ type: 'error', message: res.data.message })
        }
      }).catch((err) => {
        setLoader(false);
        setRegisterOtpVerifiedIputShow(false);
        ToastMessage({ type: 'error', message: 'Already Registered !' });
      })
    }).catch((err) => {
      setLoader(false);
      ToastMessage({ type: 'error', message: 'Invalid Otp Code' });
      resetOtp();
    })
    // console.log(registerData);
    // console.log(register_otp);
    // return;
  }

  function resetOtp() {
    setVerifiedOtp(false);
    setnumber('');
    setfinal('');
  }

  function clearLogin() {
    localStorage.clear();
  }

  function changeLoginType(type) {
    setLoginUI(type)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login{clearLogin()}</title>
      </MetaTags>


      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Link to="/" className="logo text-center mb-4">
              <img src={logoSm} height="110" alt="logo" />
            </Link>

            <Col md={8} lg={6} xl={4}>
              <div className="form-horizontal form-wizard-wrapper user-login-tab clearfix">
                <div className="steps clearfix">
                  <ul className="user-login-tab-container">
                    <li className={"nav-item" + (loginUI === 'Login' ? ' current' : '')} onClick={() => changeLoginType('Login')}>
                      <a className="current nav-link">Login</a>
                    </li>
                    <li className={"nav-item" + (loginUI === 'Register' ? ' current' : '')} onClick={() => changeLoginType('Register')}>
                      <a className="current nav-link">Register</a>
                    </li>
                  </ul>
                </div>
              </div>


              {loginUI == 'Login' ?
                <Card className="overflow-hidden login-card">
                  <div className="bg-user-login">
                    <div className="text-primary text- text-center p-4">
                      <h5 className="text-white font-size-20">
                        Welcome Back !
                        </h5>
                      <p className="text-white">
                        Sign in to continue to UmaChhatra.
                        </p>
                    </div>
                  </div>
                  <CardBody className="p-4">
                    <div className="p-3">
                      {!verifiedOtp ?
                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                          }}
                        >
                          {props.error && typeof props.error === "string" ? (
                            <Alert color="danger">{props.error}</Alert>
                          ) : null}



                          <div className="mb-4" className="d-flex login-mobile">
                            <div className="col-md-4">
                              <AvField type="select" value="+91" name="login_country_code" label="Country Code">
                                {
                                  countries.map((res) => {
                                    return <option>{res.code}</option>
                                  })
                                }
                              </AvField>
                            </div>
                            <div className="col-md-8 ml-4">
                              <AvField
                                name="mobile"
                                label="Mobile Number"
                                className="form-control"
                                placeholder="Enter Mobile Number"
                                type="text"
                                required
                                validate={{
                                  required: { value: true },
                                  pattern: { value: "^[#0-9]+$", errorMessage: "Invalid Mobile Number", },
                                  maxLength: { value: 10, errorMessage: "Mobile Number Should have 10 Digits", },
                                }}
                              />
                            </div>

                          </div>
                          <div id="recaptcha" className="mb-3"></div>

                          {/* <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            value="123456"
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div> */}

                          <Row className="mb-3">
                            <Col sm={12} className="text-center">
                              <button className="btn btn-danger w-md waves-effect waves-light" type="submit"> Log In {loader ? <div class="lds-dual-ring"></div> : ""}</button>
                            </Col>
                          </Row>


                        </AvForm>
                        : <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={(e, v) => {
                            handleValidSubmitOtp(e, v)
                          }}
                        >
                          {props.error && typeof props.error === "string" ? (
                            <Alert color="danger">{props.error}</Alert>
                          ) : null}

                          <div className="mb-3">
                            <AvField
                              name="otp"
                              label={"Please Enter OTP (" + mynumber + ")"}
                              value=""
                              className="form-control"
                              placeholder="Enter Otp"
                              type="number"
                              required
                              validate={{
                                required: { value: true },
                                pattern: { value: "^[#0-9]+$", errorMessage: "Invalid OTP Number", },
                              }}
                            />
                          </div>
                          <Row className="mb-3">
                            <Col sm={8} className="text-center">
                              <button className="btn btn-danger w-md waves-effect waves-light" type="submit"> Verify Otp {loader ? <div class="lds-dual-ring"></div> : ""}</button>
                            </Col>
                            <Col sm={4} className="text-center">
                              <button className="btn btn-waring w-md waves-effect waves-light" type="button" onClick={() => resetOtp()}> Cancel </button>
                            </Col>
                          </Row>


                        </AvForm>
                      }
                      {/* <Row className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/users/forget-password">
                            <i className="mdi mdi-lock"></i> Forgot your password?
                          </Link>
                        </div>
                      </Row> */}
                    </div>
                  </CardBody>
                </Card>
                :
                <Card className="overflow-hidden login-card">
                  <div className="bg-user-login">
                    <div className="text-primary text- text-center p-4">
                      <h5 className="text-white font-size-20">
                        This scheme is for Patidar only.
                        </h5>
                      <p className="text-white">
                        Sign up to continue to UmaChhatra.
                        </p>

                    </div>
                  </div>

                  <CardBody className="">
                    <div className="">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleRegisterSubmit(e, v)
                        }}
                      >
                        {props.error && typeof props.error === "string" ? (
                          <Alert color="danger">{props.error}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <AvField name="firstName" label="First Name" value="" className="form-control" placeholder="Enter First Name" type="text"
                            errorMessage="Enter First Name"
                            disabled={registerOtpVerifiedInputShow}
                            validate={{
                              required: { value: true },
                              maxLength: { value: 50, errorMessage: "Max 50 Characters." },
                            }} />
                        </div>

                        <div className="mb-3">
                          <AvField name="lastName" label="Last Name" value="" className="form-control" placeholder="Enter Last Name" type="text"
                            errorMessage="Enter Last Name"
                            disabled={registerOtpVerifiedInputShow}
                            validate={{
                              required: { value: true },
                              maxLength: { value: 50, errorMessage: "Max 50 Characters." },
                            }} />
                        </div>

                        <div className="mb-3">
                          <AvField name="email" label="Email" className="form-control" placeholder="Enter email" type="email"
                            errorMessage="Enter Email"
                            disabled={registerOtpVerifiedInputShow}
                            validate={{
                              required: { value: true },
                              pattern: { value: "/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/", errorMessage: "Invalid Email", },
                              maxLength: { value: 150, errorMessage: "Max 150 Characters." },
                            }} />
                        </div>

                        <div className="mb-3">
                          <AvRadioGroup inline className="register-radio" name="accountType" label="Account Type" required>
                            <AvRadio label="Indian" value="Indian" />
                            <AvRadio label="NRI" value="NRI" />
                          </AvRadioGroup>
                        </div>


                        <div className="mb-4" className="d-flex login-mobile">
                          <div className="col-md-3">
                            <AvField type="select" value="+91" name="register_country_code" label="Country Code">
                              {
                                countries.map((res) => {
                                  return <option>{res.code}</option>
                                })
                              }
                            </AvField>
                          </div>
                          <div className="col-md-8 ml-4">
                            <div className="mb-3">
                              <AvField name="mobileNo" label="Mobile Number" className="form-control" placeholder="Enter Mobile Number" type="text"
                                disabled={registerOtpVerifiedInputShow}
                                validate={{
                                  required: { value: true },
                                  pattern: { value: "^[#0-9]+$", errorMessage: "Invalid Mobile Number", },
                                  maxLength: { value: 10, errorMessage: "Mobile Number Should have 10 Digits", },
                                }} />
                            </div>
                          </div>
                        </div>



                        {registerOtpVerifiedInputShow ?
                          <div className="mb-3">
                            <AvField name="register_otp" label="Otp" className="form-control" placeholder="Enter Otp" type="text"
                              onChange={(e) => setRegisterOtp(e.target.value)}
                              validate={{
                                required: { value: true },
                                pattern: { value: "^[#0-9]+$", errorMessage: "Invalid Otp Number", },
                                maxLength: { value: 10, errorMessage: "Otp Number Should have 10 Digits", },
                              }} />
                          </div>
                          : <div id="recaptcha_register" className="mb-3"></div>
                        }


                        {!registerOtpVerifiedInputShow ?
                          <Row className="mb-3">
                            <Col sm={12} className="text-center">
                              <button className="btn btn-danger w-md waves-effect waves-light" type="submit"> Verify Otp {loader ? <div class="lds-dual-ring"></div> : ""}</button>
                            </Col>
                          </Row>
                          :
                          <Row className="mb-3">
                            <Col sm={12} className="text-center">
                              <button className="btn btn-danger w-md waves-effect waves-light" onClick={(e) => submitRegisterAfterOtpVerification()} type="button"> Register {loader ? <div class="lds-dual-ring"></div> : ""}</button>
                            </Col>
                          </Row>
                        }

                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}