import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"

import { connect } from "react-redux"
import { Form, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Input, Button, Col, Collapse } from "reactstrap"

import { Link } from "react-router-dom"

// Import menuDropdown
import LanguageDropdown from "./LanguageDropdown"
import logodarkImg from "../../assets/images/umachattra-logo.png";
import logosmImg from "../../assets/images/umachattra-logo.png";
import logolightImg from "../../assets/images/umachattra-logo.png";
import visvUmiyaFoundationLogo from "../../assets/images/visv-umiya-foundation.png";

import { NavLink } from 'react-router-dom';


//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = props => {
  const [active, setActive] = useState(false)
  const [isResponsive, setResponsive] = useState(false)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  useEffect(() => {
    console.log(props);
    tToggle()
  })

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 870) {
      console.log('responsive')
      setResponsive(true)
      body.classList.toggle("sidebar-enable");
    } else {
      setResponsive(false)
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  function toggleSideMenu() {
    let aside = document.getElementById("aside")
    aside.classList.add("open-sidenav")
  }

  function toggleCloseSideMenu() {
    let aside = document.getElementById("aside")
    aside.classList.remove("open-sidenav")
  }

  return (

    <React.Fragment>

      <header id="page-topbar">
        <div className="navbar-content user-nav">
          <div className="d-flex nav-content-link">
            <div className="row nav-top">
              <div className="col-md-7 d-flex justify-content-center nav-menu-align">
                <a className="navbar-external-link-content cursor-pointer"  href="https://vivah.vishvumiyafoundation.org/" target="_blank"> {props.t("Matrimonial Portal")}</a>
                <span className="menu-sepr"> | </span>
                <a className="navbar-external-link-content cursor-pointer" href="https://umaswadam.vishvumiyafoundation.org/" target="_blank"> {props.t("Umaswadmam")}</a>
                <span className="menu-sepr"> | </span>
                <a className="navbar-external-link-content cursor-pointer" href="https://vishvumiyafoundation.org/donate-us/" target="_blank"> {props.t("Donate Now")}</a>
              </div>
              <div className="col-md-3 d-flex language-section">
                <LanguageDropdown />
              </div>
              <div className="col-md-2 d-flex auth-section">
                <div className="navbar-link-login"><Link to="/users/login">Log In</Link></div>
              </div>
            </div>
          </div>
          {isResponsive ?
            <button className="menu-button" onClick={() => toggleSideMenu()}> <i className={"ti-menu"}></i></button>
            : ""}
        </div>

        <div className="d-flex nav-menu-second">
          <div className="row nav-top">
            <div className="logo-outer-container">
              <div className="logo-inner-wrapper">
                <img src={logosmImg} alt="UmaChattra-logo" height="80" />
                <img src={visvUmiyaFoundationLogo} alt="viv-umiya-foundation-logo" height="80" />
              </div>
            </div>

            {!isResponsive ?
              <div className="col-12 d-flex-wrap menu-container justify-content-center">
                <NavLink className="menu-item removeActive" to="/users/home" aria-label="Home" isActive={(match, location) => {
                  if (location.pathname === '/users/home') { () => setActive(true) } else { () => setActive(false) }
                  return match;
                }}> <i className={active ? "ti-home active" : "ti-home custom-icon"}></i></NavLink>
                <a className="menu-item" href="https://vishvumiyafoundation.org/about-us/"> {props.t("About Us")} </a>
                <a className="menu-item" href="https://vishvumiyafoundation.org/donation-appeal/"> {props.t("Donation")} </a>
                <a className="menu-item"> {props.t("UmaChattra Plan")} </a>  {/* Create Page */}
                <a className="menu-item" href="https://vishvumiyafoundation.org/events/"> {props.t("Events")} </a> {/* PDF REDIRECT */}
                <a className="menu-item" href="https://vishvumiyafoundation.org/projects/"> {props.t("Projects")} </a> {/* PDF REDIRECT */}
                <NavLink className="menu-item" activeClassName="active" to="/users/contactus"> {props.t("Contact Us")} </NavLink>
              </div>
              :
              <nav
                className="navbar navbar-light navbar-expand-lg topnav-menu"
                id="navigation"
              >
                <aside id="aside">
                  <button className="close-button" onClick={() => toggleCloseSideMenu()}> <i className={"ti-close"}></i></button>
                  <nav>
                    <NavLink className="menu-item-responsive" aria-label="Home" activeClassName="active" to="/users/home"> {props.t("Home")} </NavLink>
                    <a className="menu-item-responsive" href="https://vishvumiyafoundation.org/about-us/"> {props.t("About Us")} </a>
                    <a className="menu-item-responsive" href="https://vishvumiyafoundation.org/donation-appeal/"> {props.t("Donation")} </a>
                    <a className="menu-item-responsive"> {props.t("UmaChattra Plan")} </a>  {/* Create Page */}
                    <a className="menu-item-responsive" href="https://vishvumiyafoundation.org/events/"> {props.t("Events")} </a> {/* PDF REDIRECT */}
                    <a className="menu-item" href="https://vishvumiyafoundation.org/projects/"> {props.t("Projects")} </a> {/* PDF REDIRECT */}
                    <NavLink className="menu-item-responsive" activeClassName="active" to="/users/contactus"> {props.t("Contact Us")} </NavLink>
                  </nav>
                </aside>
              </nav>
            }
          </div>
        </div>


      </header>

    </React.Fragment >
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
