export const VolunteerAccessRoute= [
    '/dashboard',
    '/contribution-list',
    '/add-member',
    '/member-not-verified-list/unverified',
    '/member-list/verified',
    "/member-list/:id",
    "/member-not-verified-list/:id",
    '/view-member/',
    '/edit-volunteer/:id',
    '/add-divangat',
    '/add-divangat/:id',
    '/divangat-not-verified-list/:id',
    '/divangat-list/:id'
]

export const allRoutes= [
    '/dashboard',
    '/contribution-list',
    '/add-member',
    '/member-not-verified-list/unverified',
    '/member-list/verified',
    '/view-member/',
    '/edit-volunteer/'
]


export const VOLUNTEER_ROLE = 'volunteer';
export const ADMIN_ROLE = 'admin';