import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button } from "reactstrap"
import Lightbox from "react-image-lightbox"
import API from '../../apiAuth';

const PaymentView = ({ payment, users }) => {
    let [imagePreview, setImagePreview] = useState({
        currentAttachment: null,
        isFits: false
    })
    let [paymentReceiptModal, setpaymentReceiptModal] = useState(false);
    let [paymentReciptData, setpaymentReciptData] = useState({})
    function assignPhotostate(imageUrl, folder_name) {
        setImagePreview({
            isFits: true,
            currentAttachment: process.env.REACT_APP_IMAGEAPI + folder_name + '/' + imageUrl
        })
    }
    function removePhotostate() {
        setImagePreview({
            isFits: false,
            currentAttachment: null
        })
    }
    useEffect(() => {
    }, [])

    function Print() {
        var divContents = document.getElementById("printablediv").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write(divContents);
        a.document.close();
        a.print();
    }

    function getPaymentInvoice(id) {
        let data = { payment_id: id }
        API.post(process.env.REACT_APP_APIURLUSER + '/payment_receipt', data).then(res => {
            if (res.data.status === 1) {
                setpaymentReceiptModal(true)
                setpaymentReciptData(res.data.data)
            }
        });
    }

    return (
        <React.Fragment>
            {imagePreview.isFits ? (
                <Lightbox
                    mainSrc={imagePreview.currentAttachment}
                    enableZoom={false}
                    onCloseRequest={() => { removePhotostate() }} />
            ) : null}
            <div className="">
                <Modal
                    size="lg"
                    isOpen={paymentReceiptModal}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myExtraLargeModalLabel"
                        >
                            Payment Receipt
                            </h5>
                        <button onClick={() => { setpaymentReceiptModal(false) }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div id="printablediv">
                            <div dangerouslySetInnerHTML={{ __html: paymentReciptData }} />;
                                        </div>
                        <Button type="button" className="text-right print-btn" color="primary" onClick={() => { Print() }}>Print</Button>

                    </div>
                </Modal>
                <div className="container-fluid">
                    <Row>
                        <div className="head-text mb-4"> Contribution Information </div>
                        <Col className="col-12">

                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Contribution Date</th>
                                        <th>Amount</th>
                                        <th>Contribution Type</th>
                                        <th>Contribution Note</th>
                                        <th>Contribution Receipt</th>
                                        <th>Contribution Invoice</th>
                                    </tr>
                                </thead>
                                {payment.length ?
                                    payment.map((res, i) => {
                                        return (
                                            <tbody key={i}>
                                                <tr>
                                                    <td>{res.payment_date}</td>
                                                    <td>{res.amount}</td>
                                                    <td>{res.payment_type}</td>
                                                    <td>{res.payment_note}</td>
                                                    <td><img src={res.payment_receipt} className="text-center" width="50" height="50" /></td>
                                                    <td><div className="uil-edit-alt text-center"> <a onClick={() => getPaymentInvoice(res.payment_id)}><i className="dripicons-cloud-download"></i></a></div></td>
                                                </tr>
                                            </tbody>)
                                    }) : ""}

                            </table>

                        </Col>
                    </Row>
                </div>

            </div>
        </React.Fragment>
    )
}

export default PaymentView
