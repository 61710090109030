import React, { useState, useEffect } from "react"
import axios from 'axios';
import { useHistory } from "react-router-dom";
import ToastMessage from 'components/Common/toastar';
import { logoutUser } from 'store/actions';


var axiosInstanceAuth = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    'Content-Type': 'application/json',
    'auth-token': localStorage.getItem('auth-token') ? localStorage.getItem('auth-token') : undefined
  }
});

axiosInstanceAuth.interceptors.request.use((config) => {
  // document.body.classList.add('loading-indicator');
  return {
    ...config,
    headers: {
      'Content-Type': 'application/json',
      'auth-token': localStorage.getItem('auth-token') ? localStorage.getItem('auth-token') : undefined
    },
    baseURL: process.env.REACT_APP_APIURL
  }
})
// export const store = configureStore()
//This allows you to intercept the response and check the status and error messages and if ncessary reject the promise.
axiosInstanceAuth.interceptors.response.use((response,props) => {
  // document.body.classList.remove('loading-indicator');
  return response
}, (error,props) => {
  // document.body.classList.remove('loading-indicator');
  if (error.toString() === 'Error: Request failed with status code 401') {
    ToastMessage({ type: 'error', message: 'Error: Request failed with status code 401' })
    return window.location.href = '/users/logout'
  } else {
    ToastMessage({ type: 'error', message: 'Internal Server Error' })

  }
  return Promise.reject(error);
})

export default axiosInstanceAuth
