import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    CustomInput,
    InputGroup,
    Form
} from "reactstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import AddDivangat from "./addDivangat";
import { ViewMember } from "./viewMember";

const EditDivangat = props => {

    let [currentSelectedTab, setCurrentSelectedTab] = useState('Divangat List')
    let [member_id, setMember_id] = useState();
    let [isViewScreen, setIsViewScreen] = useState();
    let { id } = useParams();
    let location = useLocation();
    const changeActiveTab = event => {
        setCurrentSelectedTab(event)
    }

    useEffect(() => {
        console.log(location);
        if (location && location.pathname) {
            isViewScreen = location.pathname.indexOf('view-member') > -1 ? true : false;
        }
        setIsViewScreen(isViewScreen)
        setMember_id(id)
        member_id = id;
    }, [])


    return (
        <React.Fragment>
            <Container className="contact-page-container" fluid={false}>
                <Card>
                    <div className="contact-title-container">
                        <Row>
                            <Col lg="12">
                                <div className="contact-us-title-container">
                                    <h3 className="text-white">{isViewScreen ? props.t('View Divangat Details') : props.t('Edit Divangat')}</h3>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {member_id && !isViewScreen ?
                        <CardBody className="contact-form-container">
                            <AddDivangat memberID={member_id} />
                        </CardBody>
                        : member_id && isViewScreen ? <CardBody className="contact-form-container">
                            <ViewMember memberID={member_id} />
                        </CardBody> : ""}
                </Card>
            </Container>
        </React.Fragment >
    )
}


EditDivangat.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(EditDivangat))

