import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoSm from "../../assets/images/umachattra-logo.png";
import API from '../../apiAuth';
import toastr from 'toastr'
import ToastMessage from 'components/Common/toastar';
const ForgetPassword = props => {

    const handleValidSubmit = (event, values) => {
        debugger
        API.post(process.env.REACT_APP_APIURLUSER + '/forgot_password', { ...values }).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message })
            } else {
                ToastMessage({ type: 'error', message: res.data.message })

            }
        })
    }


    return (
        <React.Fragment>
            <MetaTags>
                <title>Forget Password</title>
            </MetaTags>


            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Link to="/" className="logo text-center mb-4">
                            <img src={logoSm} height="110" alt="logo" />
                        </Link>

                        <Col md={8} lg={6} xl={4}>
                            <Card className="overflow-hidden">
                                <div className="bg-user-login">
                                    <div className="text-primary text- text-center p-4">
                                        <h5 className="text-white font-size-20"> Forgot Password ! </h5>
                                        <p className="text-white"> Add email Id to reset.</p>
                                    </div>
                                </div>

                                <CardBody className="p-4">
                                    <div className="p-3">
                                        <AvForm
                                            className="form-horizontal mt-4"
                                            onValidSubmit={(e, v) => {
                                                handleValidSubmit(e, v)
                                            }}
                                        >
                                            {props.error && typeof props.error === "string" ? (
                                                <Alert color="danger">{props.error}</Alert>
                                            ) : null}

                                            <div className="mb-3">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    value="admin@themesbrand.com"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    required
                                                />
                                            </div>

                                            <Row className="mb-3 d-flex justify-content-space-around">
                                                <Col sm={6}>
                                                    <button className="btn btn-danger w-md waves-effect waves-light" type="submit"> Submit </button>
                                                </Col>
                                                <Col sm={6} className="text-right">
                                                    <Link to="/users/login">
                                                        <button className="btn btn-warning w-md waves-effect waves-light" type="submit"> Back to Login </button> </Link>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3 text-right">

                                            </Row>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}

export default ForgetPassword
