import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    CustomInput,
    InputGroup,
    Form,
    Modal
} from "reactstrap"
import ToastMessage from "components/Common/toastar";
import API from '../../apiAuth';
import Flatpickr from "react-flatpickr"
import { convertErrorMessage } from "components/Common/converErrorMessage";
import { PaymentType } from "common/list";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";

const PaymentResponse = props => {

    let { id } = useParams()
    let [token_id, setTokenId] = useState();
    let [paymentAction, setPaymentAction] = useState();

    useEffect(() => {
        setTokenId(id);
        token_id = id;
        if (token_id) {
            checkValidToken();
        }
    }, []);

    function checkValidToken() {
        API.get(process.env.REACT_APP_APIURLUSER + '/online_status_check/' + token_id).then(res => {
            setPaymentAction(res.data.data)
        }).catch(error => {

        });
    }

    function Print() {
        var divContents = document.getElementById("printablediv").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write(divContents);
        a.document.close();
        a.print();
    }



    return (
        <div className="App">
            <div className="container-fluid">
                <div className="d-flex content-flex-right">
                    <Button type="button" className="text-right" color="primary" onClick={() => { Print() }}>Print</Button>
                </div>
                <Card>
                    <CardBody>
                        <div id="printablediv">
                            <div dangerouslySetInnerHTML={{ __html: paymentAction }} />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div >
    );
}

PaymentResponse.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(PaymentResponse))
