import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import API from '../../apiAuth';
import APICommon from '../../apiAuth';

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    CustomInput,
    InputGroup,
    Form,
    Modal
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import { useHistory, useParams } from "react-router-dom";

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from 'axios';

import { PaymentType, BloodGroup, Gender, MaritalStatus, Cast, RelationList, OccupationType, ContributionType } from "common/list";
import ToastMessage from "components/Common/toastar";
import Lightbox from "react-image-lightbox"
import SweetAlert from "react-bootstrap-sweetalert"
import ImageView from "components/Common/imageView";
import PaymentView from "components/Common/paymentView";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { MemberList } from "./memberList";
import "react-image-lightbox/style.css"
import noImage from "../../assets/images/no-image.png"

const optionGroup2 = PaymentType
let state_id;
let isChecked = false;
const AddMember = props => {
    const [formData, setFormData] = useState({
        plan_id: "",
        plan_info: {
            plans_id: "",
            plan_name: "",
            plans_amount: "",
            plans_payment_type: "",
            plans_validity_age: 55,
            plans_age_min: 18,
            plans_age_max: 55,
            approx_year_payment: "",
            status_plans: true
        },
        name: "",
        father_name: "",
        grandfather_name: "",
        surname_name: "",
        blood_group: "N/A",
        current_address: "",
        village: "",
        state: "",
        city: "",
        district: "",
        pin_code: "",
        mobile_no_1: "",
        mobile_no_2: "",
        email: "",
        gender: "Male",
        marital_status: "Unmarried",
        cast: "Kadva",
        other_cast_name: "",
        birth_date: "",
        birth_place: "",
        native_place: "",
        age: "",
        education: "",
        samaj: "",
        occupation: "Bussiness",
        other_occupation: "",
        work_address: "",
        work_pin_code: "",
        pan_card_no: "",
        aadhar_cad_no: "",
        family_info: { "member": [{ "name": "", "age": "", "relation": "Father", "mobile_no": "" }] },
        past_health_problems: "",
        referencing_given_name: "",
        rf_address: "",
        rf_mobile_no: "",
        rf_pin_code: "",
        witness_name: "",
        witness_mobile_no: "",
        vuf_witness_name: "",
        vuf_witness_mobile_no: "",
        donor_name: "",
        donor_mobile_no: "",
        donor_address: "",
        donor_pin_code: ""
    });

    const [errorFormData, errorSetFormData] = useState({
        name: null,
        father_name: null,
        grandfather_name: null,
        surname_name: null,
        blood_group: null,
        current_address: null,
        village: null,
        state: null,
        city: null,
        district: null,
        pin_code: null,
        mobile_no_1: null,
        mobile_no_2: null,
        email: null,
        gender: null,
        marital_status: null,
        cast: null,
        other_cast_name: null,
        birth_date: null,
        birth_place: null,
        native_place: null,
        age: null,
        education: null,
        samaj: null,
        occupation: null,
        other_occupation: "",
        work_address: null,
        work_pin_code: null,
        pan_card_no: null,
        payment_amount: null,
        family_info: null,
        past_health_problems: null,
        referencing_given_name: null,
        rf_address: null,
        rf_mobile_no: null,
        rf_pin_code: null,
        witness_name: null,
        witness_mobile_no: null,
        vuf_witness_name: null,
        vuf_witness_mobile_no: null,
        donor_name: "",
        donor_mobile_no: "",
        donor_address: "",
        donor_pin_code: ""
    });

    const [formDataUploads, setFormDataUploads] = useState({
        user_images: null,
        user_aadhar_cad: null,
        user_pan_card: null,
        user_cheque: null,
        blood_group: null,
        user_signechar: null,
    });

    const [formPaymentInformation, setFormPaymentInformation] = useState({
        payment_receipt: null,
        amount: null,
        payment_date: null,
        payment_type: PaymentType[0].value,
        payment_note: null
    });

    const [typeofPayment, setTypeofPayment] = useState({});
    const [onlinePaymentAmounnt, setOnlinePaymentAmount] = useState({});

    const [errorFormPaymentFormData, errorSetFormPaymentInformation] = useState({
        payment_receipt: null,
        amount: null,
        payment_date: null,
        payment_type: PaymentType[0].value,
        payment_note: null
    });

    const [formNachInformation, setFormNachInformation] = useState({
        ifsc: null,
        micr: null,
        bank_name: null,
        bank_holder_name: null,
        frequency: "One Time",
        account_number: null,
        payment_type: "",
        bank_cheque_no: null,
    });

    const [errorFormNachInformation, errorSetFormNachInformation] = useState({
        ifsc: null,
        micr: null,
        bank_name: null,
        bank_holder_name: null,
        frequency: "One Time",
        account_number: null,
        payment_type: "",
        bank_cheque_no: null,
    });

    let [allPlanInfo, setPlanInfo] = useState([])
    let [allStateArray, setStateArray] = useState([])
    let [allDistrictArray, setDistrictArray] = useState([])
    let [allCityArray, setCityArray] = useState([])
    let [currentIndex, setCurrentIndex] = useState(1);
    let [planSelectedInfo, setPlanSelectedInfo] = useState();
    let [imagePreview, setImagePreview] = useState({
        currentAttachment: null,
        isFits: false
    })
    let [member_id, setMember_id] = useState();
    let { id } = useParams()
    const history = useHistory();
    let otherOccupation;
    let [currentIndexForArray, setCurrentIndexForArray] = useState()
    let [otherOccupationError, setOtherOccupationError] = useState()
    let [showError, setShowError] = useState(false)
    let [paymentShowView, setPaymentShowView] = useState([]);
    let [enableMemberFormModal, setEnableMemberFormModal] = useState(true);
    let [emailForm, setEmailForm] = useState(null);

    function handleAddRowNested() {
        formData.family_info.member.push({
            "name": "", "age": "", "relation": "Father", "mobile_no": ""
        })
        const item = formData.family_info.member
        setFormData({
            ...formData,
            family_info: {
                member: item
            }
        });
    }

    function validate(name, value) {
        if (name === 'name' || name === 'father_name' || name === 'grandfather_name' || name === 'surname_name' || name === 'village' || name === 'district' || name === 'current_address' ||
            name === 'birth_place' || name === 'native_place' || name === 'education' || name === 'samaj' || name === 'email' || name === 'occupation' || name === 'work_address' || name === 'work_pin_code' ||
            name === 'past_health_problems') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                return "";
            }
        }

        if (planSelectedInfo && planSelectedInfo.plans_payment_type.toLowerCase() !== 'one time') {
            // if (name === 'donor_address' || name === 'donor_mobile_no' || name === 'donor_name' || name === 'donor_pin_code') {
            //     if (!value) {
            //         return "Please enter " + convertErrorMessage(name);
            //     } else {
            //         return "";
            //     }
            // }
            switch (name) {
                case "donor_mobile_no":
                    if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                        return "Enter a valid donor mobile number";
                    } else {
                        return "";
                    }
                case "donor_pin_code":
                    if (value && (!value.match(/^[0-9]*$/i) || value.length > 6)) {
                        return "Enter a valid pin code";
                    } else {
                        return "";
                    }
                default: {
                    return "";
                }
            }
        }

        switch (name) {
            case "email":
                if (value && (!value.match(/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i))) {
                    return "Enter a valid email address";
                }
                else {
                    return "";
                }
            case "mobile_no_1":
                if (!value) {
                    return "Please enter mobile number 1";
                } else if (!value.match(/^[0-9]*$/i) || value.length > 10) {
                    return "Enter a valid mobile number 1";
                } else {
                    return "";
                }
            case "mobile_no_2":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid mobile number 2";
                } else {
                    return "";
                }
            case "witness_mobile_no":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid witness mobile number";
                } else {
                    return "";
                }
            case "vuf_witness_mobile_no":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid witness mobile number";
                } else {
                    return "";
                }

            case "pan_card_no":
                if (!value) {
                    return "Please enter pan card";
                } else if (!value.match(/^[a-z0-9]*$/i) || value.length > 10) {
                    return "Enter a valid pan card";
                } else {
                    return "";
                }
            case "aadhar_cad_no":
                if (!value) {
                    return "Please enter pan card";
                } else if (!value.match(/^[a-z0-9]*$/i) || value.length != 12) {
                    return "Enter a valid aadhar card";
                } else {
                    return "";
                }
            case "pin_code":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 6)) {
                    return "Enter a valid pin code";
                } else {
                    return "";
                }
            case "rf_pin_code":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 6)) {
                    return "Enter a valid pin code";
                } else {
                    return "";
                }
            case "work_pin_code":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 6)) {
                    return "Enter a valid pin code";
                } else {
                    return "";
                }

            default: {
                return "";
            }
        }
    }

    function validatePaymentForm(name, value) {
        if (name === 'payment_receipt' || name === 'amount' || name === 'payment_date' || name === 'payment_note') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                return "";
            }
        }


        switch (name) {
            case "amount":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid amount";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    }

    function validateNachForm(name, value) {
        if (name === 'bank_name' || name === 'bank_cheque_no' || name === 'bank_holder_name' || name === 'ifsc' || name === 'micr' || name === 'account_number') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                return "";
            }
        }
        switch (name) {
            case "account_number":
                if (value && (!value.match(/^[0-9]*$/i))) {
                    return "Enter a valid bank account number";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    }

    function convertErrorMessage(e) {
        return e.replace(/_/g, " ");
    }

    function removeNestedRows(index) {
        currentIndexForArray = index;
        setCurrentIndexForArray(index)
        setShowError(true);
    }

    function deleteRow() {
        formData.family_info.member.splice(currentIndexForArray, 1)
        const item = formData.family_info.member
        setFormData({
            ...formData,
            family_info: {
                member: item
            }
        });
        setShowError(false);
        currentIndexForArray = undefined;
        setCurrentIndexForArray(undefined)
    }

    useEffect(() => {
        setMember_id(props.memberID)
        member_id = id;
        if (props.memberID) {
            getSingleMemberDetail();
        } else {
            getAllState()
        }
        getAllPlans();

    }, [])


    function getPlanFromSelectedID(e) {
        let id = e.target ? e.target.value : e;
        let plan = allPlanInfo.find(res => res.plans_id.toString() === id.toString());
        setPlanSelectedInfo(plan)
        if (plan)
            setOnlinePaymentAmount(plan.plans_amount)
    }

    function getSingleMemberDetail() {
        let data = { member_id: member_id }
        API.post(process.env.REACT_APP_APIURLUSER + '/single_member', data).then(res => {
            if (res.data.status === 1) {
                let data = res.data.data.member;
                let bank_nach = res.data.data.bank_nach ? res.data.data.bank_nach[0] : undefined;
                let payment = res.data.data.payment ? res.data.data.payment : undefined
                delete data.status_verified;
                delete data.member_id;
                delete data.plan_info.status_plans;
                setOnlinePaymentAmount(data.plan_info.plans_amount)

                if (bank_nach) {
                    delete bank_nach.updatedAt;
                    delete bank_nach.createdAt;
                    delete bank_nach.id;
                    delete bank_nach.member_id;
                    setFormNachInformation(bank_nach);
                }
                setPaymentShowView(payment);
                setFormData(data);
                formData.state = data.state;
                formData.district = data.district;
                formData.plan_id = data.plan_id;
                setFormDataUploads({
                    ...formDataUploads,
                    user_images: data.user_images,
                    user_aadhar_cad: data.user_aadhar_cad,
                    user_pan_card: data.user_pan_card,
                    user_cheque: bank_nach ? bank_nach.user_cheque : "",
                    user_signechar: data.user_signechar,
                })
                setEmailForm({
                    email: data.email,
                    mobile_no_1: data.mobile_no_1,
                    mobile_no_2: data.mobile_no_2
                })
                getAllState();
                getPlanFromSelectedID(data.plan_id);
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    function validateExistingEmailOrMobile(text, form, type) {
        const str = text.target.value;
        if (member_id) {
            if (type === 'email' && emailForm.email === formData.email) {
                return
            }
            if (type === 'mobile' && emailForm.mobile_no_1 === formData.mobile_no_1) {
                return
            }
        }
        let isEnable = false;
        let data = { type: type };
        if (type === 'mobile') {
            if (str && str.match(/^[0-9]*$/i) && str.length == 10) {
                isEnable = true;
                data.mobile_no_1 = str;
            } else {
                isEnable = false;
            }
        } else {
            if (str && str.match(/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i)) {
                isEnable = true;
                data.email = str;
            } else {
                isEnable = false;
            }
        }

        if (isEnable) {
            APICommon.post('/comman/check_user', data).then(res => {
                if (res.data.status === 0) {
                    errorSetFormData({
                        ...errorFormData,
                        [text.target.name]: res.data.message
                    });
                } else {
                    errorSetFormData({
                        ...errorFormData,
                        [text.target.name]: ""
                    });
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Internal Server Error' })
            });
        }
    }

    function convertDate(date) {
        if (date) {
            var parts = date.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    }

    function getAllPlans() {
        API.get(process.env.REACT_APP_APIURLUSER + '/all_plans').then(res => {
            if (res.data.status === 1) {
                setPlanInfo(res.data.data);
                allPlanInfo = res.data.data;
                if (!member_id) {
                    formData.plan_id = res.data.data[0].plans_id
                    getPlanFromSelectedID(res.data.data[0].plans_id)
                } else {
                    getPlanFromSelectedID(formData.plan_id)
                }
            }
        }).catch(error => {

        });
    }

    function getAllState() {
        APICommon.get('comman/all_state').then(res => {
            if (res.data.status === 1) {
                setStateArray(res.data.data);
                if (!id) {
                    getAllDistrictFromState(res.data.data[0].state_id, true);
                    formData.state = res.data.data[0].state_title;
                    state_id = res.data.data[0].state_id;
                } else {
                    state_id = formData.state ? res.data.data.find(res => res.state_title === formData.state).state_id : null;
                    getAllDistrictFromState(state_id, false)
                }
            }
        }).catch(error => {

        });
    }

    function getAllDistrictFromState(state_id, isChangeFromUI) {
        let data = { state_id: state_id }
        APICommon.post('comman/state_district', data).then(res => {
            if (res.data.status === 1) {
                setDistrictArray(res.data.data);
                if (!id || isChangeFromUI) {
                    getAllCity(state_id, res.data.data[0].district_id, true);
                    formData.district = res.data.data[0].district_title;
                } else {
                    let district_id = formData.district ? res.data.data.find(res => res.district_title === formData.district).district_id : null;
                    getAllCity(state_id, district_id, false)
                }
            }
        }).catch(error => {

        });
    }

    function getAllCity(state, district, isChangeFromUI) {
        let data = { state_id: state, district_id: district }
        APICommon.post('comman/state_district_city', data).then(res => {
            if (res.data.status === 1) {
                setCityArray(res.data.data);
                if (!id || isChangeFromUI) {
                    formData.city = res.data.data[0].name;
                }
                // if (member_id && !isChecked) {
                //     isChecked = true

                // }
            }
        }).catch(error => {

        });
    }

    const onStateChange = e => {
        state_id = e.target.value ? allStateArray.find(res => res.state_title === e.target.value).state_id : null;
        formData.state = e.target.value;
        getAllDistrictFromState(state_id, true)
    }

    const onDistrictChange = e => {
        let district_id = e.target.value ? allDistrictArray.find(res => res.district_title === e.target.value).district_id : null;
        formData.district = e.target.value;
        getAllCity(state_id, district_id, true)
    }

    const onCityChange = e => {
        formData.city = e.target.value;
        setFormData({
            ...formData,
            city: e.target.value
        })
    }

    const updateFormData = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
        errorSetFormData({
            ...errorFormData,
            [event.target.name]: validate(event.target.name, event.target.value)
        });
    }

    const updatePaymentFormData = event => {
        setFormPaymentInformation({
            ...formPaymentInformation,
            [event.target.name]: event.target.value
        });
        errorSetFormPaymentInformation({
            ...errorFormPaymentFormData,
            [event.target.name]: validatePaymentForm(event.target.name, event.target.value)
        });
    }

    const updateNachFormData = event => {
        setFormNachInformation({
            ...formNachInformation,
            [event.target.name]: event.target.value
        });
        errorSetFormNachInformation({
            ...errorFormNachInformation,
            [event.target.name]: validateNachForm(event.target.name, event.target.value)
        });
    }

    const updateFamilyInformationFormData = event => {
        let index = event.target.name.split('-')[0];
        let name = event.target.name.split('-')[1];
        formData.family_info.member[index][name] = event.target.value;
        const item = formData.family_info.member;
        setFormData({
            ...formData,
            family_info: {
                member: item
            }
        });
    }

    const assigDateAndAge = e => {
        let date = new Date(e[0])
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currerntYear = new Date().getFullYear();
        formData.birth_date = day + '-' + month + '-' + year;
        formData.age = currerntYear - year;
        // setFormData({
        //     ...formData,
        //     birth_date: day + '-' + month + '-' + year,
        //     age: age
        // });
    }

    const updatePaymentDate = e => {
        let date = new Date(e[0])
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        setFormData({
            ...formData,
            payment_date: day + '-' + month + '-' + year,
        });
    }

    const updatePaymentDateSubmitForm = e => {
        let date = new Date(e[0])
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        setFormPaymentInformation({
            ...formPaymentInformation,
            payment_date: day + '-' + month + '-' + year,
        });
        errorSetFormPaymentInformation({
            ...errorFormPaymentFormData,
            'payment_date': validatePaymentForm('payment_date', year)
        });
    }


    const { plan_name, plans_amount, plans_validity_age } = formData;

    function uploadMemberAttachment(upload, type, key) {
        upload.preventDefault()
        if (validateImageUpload(upload)) {
            var bodyFormData = new FormData();
            bodyFormData.append([key], upload.target.files[0]);
            API.post(process.env.REACT_APP_APIURLUSER + '/' + type + '/' + member_id, bodyFormData).then(res => {
                if (res.data.status === 1) {
                    if (key === 'user') {
                        formDataUploads.user_images = res.data.data;
                        setFormDataUploads({ ...formDataUploads, user_images: res.data.data })
                    } else if (key === 'aadhar') {
                        formDataUploads.user_aadhar_cad = res.data.data;
                        setFormDataUploads({ ...formDataUploads, user_aadhar_cad: res.data.data })
                    } else if (key === 'pan') {
                        formDataUploads.user_pan_card = res.data.data;
                        setFormDataUploads({ ...formDataUploads, user_pan_card: res.data.data })
                    } else if (key === 'cheque') {
                        formDataUploads.user_cheque = res.data.data;
                        setFormDataUploads({ ...formDataUploads, user_chequesignechar: res.data.data })
                    } else if (key === 'signechar') {
                        formDataUploads.user_signechar = res.data.data;
                        setFormDataUploads({ ...formDataUploads, user_signechar: res.data.data })
                    }
                    ToastMessage({ type: 'success', message: res.data.message })
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch((error) => {
                ToastMessage({ type: 'error', message: 'Internal Server Error' })

            })
        }
    }

    function validateImageUpload(image) {
        var filePath = image.target.value;
        // Allowing file type
        var allowedExtensions =
            /(\.jpg|\.jpeg|\.png)$/i;

        if (!allowedExtensions.exec(filePath)) {
            ToastMessage({ type: 'error', message: 'Invalid file type' })
            image.value = '';
            return false;
        } else {
            return true;
        }
    }

    function handleSubmit(e) {
        e.preventDefault()
        let arraysFamilyInfo = formData.family_info.member;
        let isError = false
        arraysFamilyInfo.map((res) => {
            if (!res.name) {
                ToastMessage({ type: 'error', message: 'Invalid Family Information' })
                isError = true;
            }
        })

        if (isError) {
            return;
        }


        if (errorFormData.mobile_no_1 || errorFormData.mobile_no_2 || errorFormData.email) {
            return
        }

        let validationErrors = {};
        Object.keys(formData).forEach(name => {
            const error = validate(name, formData[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            errorSetFormData(validationErrors);
            return;
        }

        let plan_info = allPlanInfo.find(res => res.plans_id === formData.plan_id)
        formData.plan_info = plan_info;


        if (member_id) {
            let data = {
                member_id: member_id,
                ...formData
            }
            API.post(process.env.REACT_APP_APIURLUSER + '/edit_member', data).then(res => {
                if (res.data.status === 1) {
                    isChecked = false;
                    isError = false;
                    setCurrentIndex(2);
                    ToastMessage({ type: 'success', message: res.data.message })
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Invalid Family Information' })
            });
        } else {
            let data = {
                payment_amount: planSelectedInfo.plans_amount,
                ...formData
            }

            API.post(process.env.REACT_APP_APIURLUSER + '/new_member', data).then(res => {
                if (res.data.status === 1) {
                    setCurrentIndex(2);
                    member_id = res.data.data.member_id;
                    setMember_id(member_id)
                    isError = false;
                    ToastMessage({ type: 'success', message: res.data.message })
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Invalid Family Information' })
            });
        }
    }


    function handlePaymentSubmit(e) {
        e.preventDefault()

        let validationErrors = {};
        formPaymentInformation.amount = planSelectedInfo.plans_amount;
        // formPaymentInformation.payment_type  =planSelectedInfo.plans_payment_type;
        Object.keys(formPaymentInformation).forEach(name => {
            const error = validatePaymentForm(name, formPaymentInformation[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            errorSetFormPaymentInformation(validationErrors);
            return;
        }

        var bodyFormData = new FormData();
        bodyFormData.append('payment_receipt', formPaymentInformation.payment_receipt);
        bodyFormData.append('amount', formPaymentInformation.amount);
        bodyFormData.append('payment_type', formPaymentInformation.payment_type);
        bodyFormData.append('payment_date', formPaymentInformation.payment_date);
        bodyFormData.append('payment_note', formPaymentInformation.payment_note);

        API.post(process.env.REACT_APP_APIURLUSER + '/member_add_payment/' + member_id, bodyFormData).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message });
                if (id) {
                    history.push('/users/member')
                } else {
                    document.getElementById('memberList').click()
                }
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    function handleOnlinePaymentSubmit(e) {
        e.preventDefault()

        if (!onlinePaymentAmounnt) {
            ToastMessage({ type: 'error', message: 'Please add valid amount' })
            return;
        }
        let validationErrors = {};

        let data = {
            "member_id": member_id,
            "amount": onlinePaymentAmounnt
        }

        API.post(process.env.REACT_APP_APIURLUSER + '/online_pay/', data).then(res => {
            if (res.data.status === 1) {
                window.open(res.data.pay_url, "_blank")
                // ToastMessage({ type: 'success', message: res.data.message });
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    function handleNachSubmit(e) {
        if (planSelectedInfo.plans_payment_type.toLowerCase() === 'one time') {
            setCurrentIndex(3);
            return
        } else {
            e.preventDefault()
            let validationErrors = {};
            Object.keys(formNachInformation).forEach(name => {
                const error = validateNachForm(name, formNachInformation[name]);
                if (error && error.length > 0) {
                    validationErrors[name] = error;
                }
            });
            if (Object.keys(validationErrors).length > 0) {
                errorSetFormNachInformation(validationErrors);
                return;
            }

            let data = {
                member_id: member_id,
                ...formNachInformation
            }

            API.post(process.env.REACT_APP_APIURLUSER + '/member_update_nach', data).then(res => {
                if (res.data.status === 1) {
                    setCurrentIndex(3);
                    ToastMessage({ type: 'success', message: res.data.message })
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Invalid Family Information' })
            });
        }
    }

    function assignSelectedIndex(index) {
        if (index === 1) {
            setCurrentIndex(index)
        }
        if (index > 1 && member_id) {
            setCurrentIndex(index)
        }
    }

    function uploadPaymentReceipt(event) {
        if (validateImageUpload(event)) {
            formPaymentInformation.payment_receipt = event.target.files[0];
            setFormPaymentInformation({
                ...formPaymentInformation,
                payment_receipt: event.target.files[0]
            })
            errorSetFormPaymentInformation({
                ...errorFormPaymentFormData,
                'payment_receipt': validatePaymentForm('payment_receipt', event.target.files[0].name)
            });
            ToastMessage({ type: 'success', message: 'Image added' })
        }
    }

    return (
        <React.Fragment>
            <Modal
                size="lg"
                isOpen={enableMemberFormModal}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >
                        ઉમાછત્ર યોજના
                    </h5>
                    <button onClick={() => { setEnableMemberFormModal(false) }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>   (નોંધ :- માત્ર પાટીદારે જ ફોર્મ ભરવું..)</div><br />
                    <div> કોઈ પણ લેવા-કડવા પાટીદાર ઉમાછત્ર યોજનાના લાભ માટે સભ્ય તરીકે જોડાઈ શકશે.</div><br />

                    <div> આટલું તૈયાર રાખશો તો ફોર્મ ભરતાં માત્ર પાંચ મિનિટ થશે...</div>
                    <div>પાન-આધાર કાર્ડ નકલ અને નંબર અપલોડ માટે..</div>
                    <div>પોતાનો ફોટો, પૂરું સરનામું અને કુંટુંબના નામો..</div>
                    <div>સભ્ય થવાનાં બે વિકલ્પમાં રૂ.31000 એક વખત.. અથવા</div>
                    <div>રૂ.4000 પ્રારંભિક બાદ દર વર્ષે 2200 આસપાસ વાર્ષિક ભરણું..</div>
                    <div>જો વાર્ષિક વિકલ્પ પસંદ કરો તો સહી સાથે ચેકનો ફોટો અપલોડ..</div>
                    <div>(એજ સહી ACH ફોર્મમાં આગળ પાછળ કરી ઓફિસે મોકલવું)</div>
                    <div>ઉમાછત્રમાં જોડાવા પ્રેરણા કરનારનું નામ તથા મોબાઈલ..</div>
                    <div>પોતાના પરિવાર સિવાયના સામાજિક સાક્ષીનું નામ તથા મોબાઈલ..</div>
                    <div> VUF હોદ્દેદાર અથવા અન્ય બીજા સાક્ષીનું નામ તથા મોબાઈલ.. </div><br />

                    <div>જો તમે આ ફોર્મ કમ્પ્યુટર અથવા ફોનમાં ભરી રહ્યા છો તો ઉપરોક્ત ડોક્યુમેન્ટ સ્કેન કરી રાખવા.</div>
                </div>
            </Modal>
            {showError ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmButtonText="Yes, delete it!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        deleteRow()
                    }}
                    onCancel={() => setShowError('')
                    }
                >
                    You won't be able to revert this!
                            </SweetAlert>
            ) : null}
            {imagePreview.isFits ? (
                <Lightbox
                    mainSrc={imagePreview.currentAttachment}
                    enableZoom={false}
                    onCloseRequest={() => { removePhotostate() }} />
            ) : null}
            <Row>
                <Col lg={12}>

                    <div className="form-horizontal form-wizard-wrapper wizard clearfix mb-4 ">
                        <div className="steps clearfix">
                            <ul>
                                <li className={"nav-item" + (currentIndex === 1 ? ' current' : '')} onClick={() => assignSelectedIndex(1)}>
                                    <a className={"current nav-link custom" + (currentIndex === 1 ? ' active' : '')}>
                                        <span className="number">1. </span>
                                        {props.t('Personal Information')}
                                    </a>
                                </li>
                                <li className={"nav-item" + (currentIndex === 2 ? ' current' : '')} onClick={() => assignSelectedIndex(2)}>
                                    <a className={"current nav-link custom" + (currentIndex === 2 ? ' active' : '')}>
                                        <span className="number">2. </span>
                                        {props.t('Id Proof')}
                                    </a>
                                </li>
                                <li className={"nav-item" + (currentIndex === 3 ? ' current' : '')} onClick={() => assignSelectedIndex(3)}>
                                    <a className={"current nav-link custom" + (currentIndex === 3 ? ' active' : '')}>
                                        <span className="number">3. </span>
                                        {props.t('Contribution Information')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {currentIndex == 1 ?
                        <form onSubmit={e => { handleSubmit(e) }}>

                            {allPlanInfo.length ?
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">{props.t('Select Contribution Option')}</label>
                                            <select className="form-control" name="plan_id" value={formData.plan_id} onChange={e => { getPlanFromSelectedID(e); updateFormData(e) }}>
                                                {allPlanInfo.map((res, i) => {
                                                    return <option key={i} value={res.plans_id}>{res.plan_name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="head-text mb-4"> {props.t('Your Yearly Contibution Details')} </div>


                                    {planSelectedInfo ?
                                        <div className="row">
                                            <div className="col-md-1 plan-view">
                                                <div className="mb-3 templating-select select2-container">
                                                    <label className="control-label">{props.t('Amount')}</label>
                                                    <div>{planSelectedInfo.plans_amount}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 plan-view">
                                                <div className="mb-3 templating-select select2-container">
                                                    <label className="control-label">{props.t('Contribution Type')}</label>
                                                    <div>{planSelectedInfo.plans_payment_type}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 plan-view">
                                                <div className="mb-3 templating-select select2-container">
                                                    <label className="control-label">{props.t('Approx Year')}</label>
                                                    <div>{planSelectedInfo.approx_year_payment}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 plan-view">
                                                <div className="mb-3 templating-select select2-container">
                                                    <label className="control-label">{props.t('Valid Age')}</label>
                                                    <div>{planSelectedInfo.plans_validity_age}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 plan-view">
                                                <div className="mb-3 templating-select select2-container">
                                                    <label className="control-label">{props.t('Max Age')}</label>
                                                    <div>{planSelectedInfo.plans_age_max}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-1 plan-view">
                                                <div className="mb-3 templating-select select2-container">
                                                    <label className="control-label">{props.t('Min Age')}</label>
                                                    <div>{planSelectedInfo.plans_age_min}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 plan-view">
                                                <div className="mb-3 templating-select select2-container">
                                                    <label className="control-label">{props.t('Eligibility')}</label>
                                                    <div>{planSelectedInfo.approx_eligibility_day}</div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                </div>
                                : ""}

                            <div className="head-text mb-4"> {props.t('Personal Information')} </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Name')}<span className="text-danger">*</span></label>
                                        <input name="name" className="form-control" maxLength="50" value={formData.name} label="Member Name" placeholder={props.t('Name')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.name}</span>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Father / Husband Name')}<span className="text-danger">*</span></label>
                                        <input className="form-control" maxLength="50" name="father_name" value={formData.father_name} label="Father Name" placeholder={props.t('Father / Husband Name')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.father_name}</span>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Grand Father/ Father-in-Law Name')}<span className="text-danger">*</span></label>
                                        <input className="form-control" maxLength="50" name="grandfather_name" value={formData.grandfather_name} label="Grand Father Name" placeholder={props.t('Grand Father/ Father-in-Law Name')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.grandfather_name}</span>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Surname')}<span className="text-danger">*</span></label>
                                        <input className="form-control" maxLength="50" name="surname_name" value={formData.surname_name} label="Surname Name" placeholder={props.t('Surname')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.surname_name}</span>
                                    </div>
                                </div>

                                <div className="col-md-1">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Blood Group')}<span className="text-danger">*</span></label>
                                        <select className="form-control" name="blood_group" value={formData.blood_group} onChange={e => updateFormData(e)}>
                                            {BloodGroup.map((res, i) => {
                                                return <option key={i} value={res.value}>{res.label}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>



                            <div className="row">
                                <div className="col-md-1">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Gender')}<span className="text-danger">*</span></label>
                                        <select className="form-control" name="gender" value={formData.gender} onChange={e => updateFormData(e)}>
                                            {Gender.map((res, i) => {
                                                return <option key={i} value={res.value}>{res.label}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <Label>{props.t('Birth Date')}</Label>
                                        <InputGroup>
                                            <Flatpickr value={convertDate(formData.birth_date)} className="form-control d-block" placeholder="dd M,yyyy" name="birth_date"
                                                onChange={e => assigDateAndAge(e)}
                                                onBlur={e => assigDateAndAge(e)}
                                                options={{ altInput: true }} />
                                        </InputGroup>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Email')}<span className="text-danger">*</span></label>
                                        <input className="form-control not-add" maxLength="70" name="email" label="Email" value={formData.email} placeholder={props.t('Email')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => { updateFormData(e); validateExistingEmailOrMobile(e, 'email', 'email') }} />
                                        <span className="text-danger">{errorFormData.email}</span>
                                    </div>
                                </div>


                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Mobile number 1')}<span className="text-danger">*</span></label>
                                        <input className="form-control" name="mobile_no_1" maxLength="10" value={formData.mobile_no_1} label="Mobile number 1" placeholder={props.t('Mobile number 1')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => { updateFormData(e); validateExistingEmailOrMobile(e, 'mobile_no_1', 'mobile') }} />
                                        <span className="text-danger">{errorFormData.mobile_no_1}</span>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Mobile number 2')}</label>
                                        <input className="form-control" name="mobile_no_2" maxLength="10" value={formData.mobile_no_2} label="Mobile number 2" placeholder={props.t('Mobile number 2')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => { updateFormData(e); validateExistingEmailOrMobile(e, 'mobile_no_2', 'mobile') }} />
                                        <span className="text-danger">{errorFormData.mobile_no_2}</span>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Pan Card Number')}<span className="text-danger">*</span></label>

                                        <input className="form-control pancard-no" maxLength="10" value={formData.pan_card_no} name="pan_card_no" label="Pan Card Number" placeholder={props.t('Pan Card Number')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.pan_card_no}</span>
                                    </div>
                                </div>



                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="control-label">Aadhar Card Number</label>
                                        <input className="form-control" maxLength="12" value={formData.aadhar_cad_no} name="aadhar_cad_no" label="Aadhar Card Number" placeholder="Aadhar Card Number" type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.aadhar_cad_no}</span>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Marital Status')}<span className="text-danger">*</span></label>
                                        <select className="form-control" name="marital_status" value={formData.marital_status} onChange={e => updateFormData(e)}>
                                            {MaritalStatus.map((res, i) => {
                                                return <option key={i} value={res.value}>{res.label}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Birth Place')}<span className="text-danger">*</span></label>
                                        <input className="form-control" maxLength="50" name="birth_place" value={formData.birth_place} label="Birth Place" placeholder="Birth Place" type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.birth_place}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Native Place')}<span className="text-danger">*</span></label>

                                        <input className="form-control" maxLength="50" name="native_place" value={formData.native_place} label="Native Place" placeholder={props.t('Native Place')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.native_place}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Past Health Problems')}<span className="text-danger">*</span></label>
                                        <input className="form-control" maxLength="50" value={formData.past_health_problems} name="past_health_problems" label="Past Health Problems" placeholder={props.t('Past Health Problems')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.past_health_problems}</span>
                                    </div>
                                </div>





                            </div>

                            <div className="row">



                                {allStateArray.length ?
                                    <div className="col-md-3">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">{props.t('State')}<span className="text-danger">*</span></label>
                                            <select className="form-control" value={formData.state} onChange={e => onStateChange(e)}>
                                                {allStateArray.map((res, i) => {
                                                    return <option key={i} value={res.state_title}>{res.state_title}</option>
                                                })}
                                            </select>

                                        </div>
                                    </div> : ''}

                                {allDistrictArray.length ?
                                    <div className="col-md-3">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">{props.t('District')}<span className="text-danger">*</span></label>
                                            <select className="form-control" value={formData.district} onChange={e => onDistrictChange(e)}>
                                                {allDistrictArray.map((res, i) => {
                                                    return <option key={i} value={res.district_title}>{res.district_title}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    : ''}

                                {allCityArray.length ?
                                    <div className="col-md-3">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">{props.t('City')}<span className="text-danger">*</span></label>
                                            <select className="form-control" value={formData.city} onChange={e => onCityChange(e)}>
                                                {allCityArray.map((res, i) => {
                                                    return <option key={i} value={res.name}>{res.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    : ''}

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Village')}<span className="text-danger">*</span></label>

                                        <input className="form-control" maxLength="50" name="village" value={formData.village} label="Village" placeholder={props.t('Village')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.village}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row">



                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Pin Code')}</label>

                                        <input className="form-control" maxLength="6" name="pin_code" value={formData.pin_code} label="Pin Code" placeholder={props.t('Pin Code')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.pin_code}</span>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Current Address')}<span className="text-danger">*</span></label>
                                        <textarea className="form-control" maxLength="100" name="current_address" value={formData.current_address} label="Current Address" placeholder={props.t('Current Address')} onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)}></textarea>
                                        <span className="text-danger">{errorFormData.current_address}</span>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Samaj')}<span className="text-danger">*</span></label>
                                        <input className="form-control" name="samaj" maxLength="50" value={formData.samaj} label="Samaj" placeholder={props.t('Samaj')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.samaj}</span>
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Cast')}<span className="text-danger">*</span></label>
                                        <select className="form-control" name="cast" value={formData.cast} onChange={e => updateFormData(e)}>
                                            {Cast.map((res, i) => {
                                                return <option key={i} value={res.value}>{res.label}</option>
                                            })}
                                        </select>

                                    </div>
                                </div>


                                {formData.cast === 'Other' ?
                                    <div className="col-md-2">
                                        <div className="mb-3">
                                            <label className="control-label">{props.t('Other Cast Name')}<span className="text-danger">*</span></label>
                                            <input className="form-control" name="other_cast_name" maxLength="50" value={formData.other_cast_name} label="Other Cast Name" placeholder={props.t('Other Cast Name')} type="text" onChange={e => updateFormData(e)}
                                                onBlur={e => updateFormData(e)} />
                                            <span className="text-danger">{errorFormData.other_cast_name}</span>
                                        </div>
                                    </div> : ''}
                            </div>

                            <div className="head-text mb-4">{props.t('Work Information')}</div>

                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Education')}<span className="text-danger">*</span></label>
                                        <input className="form-control" maxLength="50" name="education" label="Education" value={formData.education} placeholder={props.t('Education')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.education}</span>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Occupation')}<span className="text-danger">*</span></label>
                                        <select className="form-control" name="occupation" value={formData.occupation} onChange={e => updateFormData(e)}>
                                            {OccupationType.map((res, i) => {
                                                return <option key={i} value={res.value}>{res.label}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                {
                                    formData.occupation === 'Other' ?
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="control-label">{props.t('Other Occupation')}<span className="text-danger">*</span></label>
                                                <input className="form-control" maxLength="50" name="other_occupation" label="Other Occupation" value={otherOccupation} placeholder={props.t('Other Occupation')} type="text" onChange={e => updateFormData(e)}
                                                    onBlur={e => updateFormData(e)} />
                                                <span className="text-danger">{otherOccupationError}</span>
                                            </div>
                                        </div>
                                        : ""
                                }

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Work Address')}<span className="text-danger">*</span></label>

                                        <textarea className="form-control" maxLength="100" value={formData.work_address} name="work_address" label="Work Address" placeholder={props.t('Work Address')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} ></textarea>
                                        <span className="text-danger">{errorFormData.work_address}</span>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Work Address Pincode')}<span className="text-danger">*</span></label>

                                        <input className="form-control" maxLength="6" value={formData.work_pin_code} name="work_pin_code" label="Work Address Pincode" placeholder={props.t('Work Address Pincode')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.work_pin_code}</span>
                                    </div>
                                </div>
                            </div>


                            <div className="head-text mb-4">{props.t('Family Information')}  </div>

                            {formData.family_info && formData.family_info.member.length ?
                                formData.family_info.member.map((res, index) => {
                                    return (<div className="row" key={index}>
                                        <div className="col-md-3 mb-3">
                                            <input className="form-control" name={index + "-name"} value={res.name} label="Name" placeholder="Name" type="text" onChange={e => updateFamilyInformationFormData(e)}
                                                onBlur={e => updateFamilyInformationFormData(e)} />
                                        </div>

                                        <div className="col-md-2 mb-3">
                                            <input className="form-control" maxLength="2" name={index + "-age"} label="Age" value={res.age} placeholder="Age" type="text" onChange={e => updateFamilyInformationFormData(e)}
                                                onBlur={e => updateFamilyInformationFormData(e)} />
                                        </div>


                                        <div className="col-md-2 mb-3">
                                            <input className="form-control" maxLength="10" name={index + "-mobile_no"} value={res.mobile_no} label="Mobile Number" placeholder="Mobile Number" type="text" onChange={e => updateFamilyInformationFormData(e)}
                                                onBlur={e => updateFamilyInformationFormData(e)} />
                                        </div>

                                        <div className="col-md-3 mb-3">
                                            <select className="form-control" name={index + "-relation"} value={res.relation} onChange={e => updateFamilyInformationFormData(e)}>
                                                {RelationList.map((res, i) => {
                                                    return <option key={i} value={res.value}>{res.label}</option>
                                                })}
                                            </select>
                                        </div>

                                        <Col lg={2} className="align-self-center mb-3">
                                            <div className="d-grid">
                                                <input data-repeater-delete type="button" onClick={() => { removeNestedRows(index) }} className="btn btn-danger" value={props.t('Delete')} />
                                            </div>
                                        </Col>
                                    </div>)
                                })
                                : "Not Arrays"}
                            <Button onClick={() => { handleAddRowNested() }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">{props.t('Add')}</Button>



                            <div className="head-text mb-4">{props.t('Reference Information')}</div>



                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Referencing Given Name')}</label>

                                        <input className="form-control" maxLength="50" value={formData.referencing_given_name} name="referencing_given_name" label="Referencing Given Name" placeholder={props.t('Referencing Given Name')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.referencing_given_name}</span>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Referencing Address')}</label>

                                        <textarea className="form-control" maxLength="100" value={formData.rf_address} name="rf_address" label="Referencing Address" placeholder={props.t('Referencing Address')} onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} ></textarea>
                                        <span className="text-danger">{errorFormData.rf_address}</span>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Referencing Mobile Number')}</label>

                                        <input className="form-control" maxLength="10" value={formData.rf_mobile_no} name="rf_mobile_no" label="Referencing Mobile Number" placeholder={props.t('Referencing Mobile Number')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.rf_mobile_no}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Referencing Pin Code')}</label>

                                        <input className="form-control" maxLength="6" value={formData.rf_pin_code} name="rf_pin_code" label="Referencing Pin Code" placeholder={props.t('Referencing Pin Code')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.rf_pin_code}</span>
                                    </div>
                                </div>
                            </div>


                            <div className="head-text mb-4">{props.t('Witness Information')}  </div>

                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Witness Name')}</label>

                                        <input className="form-control" maxLength="50" value={formData.witness_name} name="witness_name" label="Witness Name" placeholder={props.t('Witness Name')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.witness_name}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Witness Mobile Number')}</label>

                                        <input className="form-control" maxLength="10" value={formData.witness_mobile_no} name="witness_mobile_no" label="Witness Mobile Number" placeholder={props.t('Witness Mobile Number')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                        <span className="text-danger">{errorFormData.witness_mobile_no}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Vuf Witness Name')}</label>

                                        <input className="form-control" maxLength="50" value={formData.vuf_witness_name} name="vuf_witness_name" label="Vuf Witness Name" placeholder={props.t('Vuf Witness Name')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Vuf Witness Mobile Number')}</label>

                                        <input className="form-control" maxLength="10" value={formData.vuf_witness_mobile_no} name="vuf_witness_mobile_no" label="Vuf Witness Mobile Number" placeholder={props.t('Vuf Witness Mobile Number')} type="text" onChange={e => updateFormData(e)}
                                            onBlur={e => updateFormData(e)} />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="head-text mb-4">{props.t('Donor Information')}  </div>

                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label className="control-label">{props.t('Donor Name')}</label>

                                            <input className="form-control" maxLength="50" value={formData.donor_name} name="donor_name" label="Donor Name" placeholder={props.t('Donor Name')} type="text" onChange={e => updateFormData(e)}
                                                onBlur={e => updateFormData(e)} />
                                            <span className="text-danger">{errorFormData.donor_name}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label className="control-label">{props.t('Donor Mobile Number')}</label>

                                            <input className="form-control" maxLength="10" value={formData.donor_mobile_no} name="donor_mobile_no" label="Donor Mobile Number" placeholder={props.t('Donor Mobile Number')} type="text" onChange={e => updateFormData(e)}
                                                onBlur={e => updateFormData(e)} />
                                            <span className="text-danger">{errorFormData.donor_mobile_no}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label className="control-label">{props.t('Donor Address')}</label>

                                            <textarea className="form-control" maxLength="100" value={formData.donor_address} name="donor_address" label="Donor Address" placeholder={props.t('Donor Address')} onChange={e => updateFormData(e)}
                                                onBlur={e => updateFormData(e)}  ></textarea>
                                            <span className="text-danger">{errorFormData.donor_address}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label className="control-label">{props.t('Donor Pin Code')}</label>

                                            <input className="form-control" maxLength="6" value={formData.donor_pin_code} name="donor_pin_code" label="Donor Pin Code" placeholder={props.t('Donor Pin Code')} type="text" onChange={e => updateFormData(e)}
                                                onBlur={e => updateFormData(e)} />
                                            <span className="text-danger">{errorFormData.donor_pin_code}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="d-flex flex-wrap gap-2">
                                <Button
                                    type="submit"
                                    color="danger"
                                    className=""
                                >{props.t('Next')}</Button>{" "}

                            </div>
                        </form>
                        : currentIndex == 2 ?
                            <div>
                                {/* <div>
                                    {formDataUploads.user_images ?
                                        <ImageView formDataUploads={formDataUploads} />
                                        : ""}
                                </div> */}
                                <form onSubmit={e => { handleNachSubmit(e) }}>
                                    <div className="row mb-3">
                                        <div className="col-md-3">
                                            {formDataUploads.user_images ?
                                                <img className="img-fluid uploads-member" alt="User-Image" src={formDataUploads.user_images} />
                                                : <img className="img-fluid uploads-member" alt="User-Image" src={noImage} />}
                                            <div className="formbold-mb-5 formbold-file-input">
                                                <label>
                                                    <input type="file" accept="image/png,image/jpg,image/jpeg" name="personal_photo" onChange={e => uploadMemberAttachment(e, 'member_photo_update', 'user')} name="file" id="file" />
                                                    <div>
                                                        <span className="formbold-drop-file">{props.t('Member (Passport) Photo')}  </span>
                                                        {/* <span className="formbold-or">{props.t('Or')}</span> */}
                                                        <span className="formbold-browse"> {props.t('Browse')} </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            {formDataUploads.user_aadhar_cad ?
                                                <img className="img-fluid uploads-member" alt="User-Image" src={formDataUploads.user_aadhar_cad} />
                                                : <img className="img-fluid uploads-member" alt="User-Image" src={noImage} />}
                                            <div className="formbold-mb-5 formbold-file-input">
                                                <label>
                                                    <input type="file" accept="image/png,image/jpg,image/jpeg" name="personal_photo" onChange={e => uploadMemberAttachment(e, 'member_aadhar_update', 'aadhar')} name="file" id="file" />
                                                    <div>
                                                        <span className="formbold-drop-file">{props.t('Aadhar Photo')} </span>
                                                        {/* <span className="formbold-or">{props.t('Or')}</span> */}
                                                        <span className="formbold-browse"> {props.t('Browse')} </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            {formDataUploads.user_pan_card ?
                                                <img className="img-fluid uploads-member" alt="User-Image" src={formDataUploads.user_pan_card} />
                                                : <img className="img-fluid uploads-member" alt="User-Image" src={noImage} />}
                                            <div className="formbold-mb-5 formbold-file-input">
                                                <label>
                                                    <input type="file" accept="image/png,image/jpg,image/jpeg" name="personal_photo" onChange={e => uploadMemberAttachment(e, 'member_pan_update', 'pan')} name="file" id="file" />
                                                    <div>
                                                        <span className="formbold-drop-file">{props.t('PAN Photo')}  </span>
                                                        {/* <span className="formbold-or"> {props.t('Or')} </span> */}
                                                        <span className="formbold-browse"> {props.t('Browse')} </span>
                                                    </div>
                                                </label>
                                            </div>

                                        </div>

                                        <div className="col-md-3">
                                            {formDataUploads.user_signechar ?
                                                <img className="img-fluid uploads-member" alt="User-Image" src={formDataUploads.user_signechar} />
                                                : <img className="img-fluid uploads-member" alt="User-Image" src={noImage} />}
                                            <div className="formbold-mb-5 formbold-file-input">
                                                <label>
                                                    <input type="file" accept="image/png,image/jpg,image/jpeg" name="personal_photo" onChange={e => uploadMemberAttachment(e, 'member_signechar_update', 'signechar')} name="file" id="file" />
                                                    <div>
                                                        <span className="formbold-drop-file"> {props.t('Signature Photo')}  </span>
                                                        {/* <span className="formbold-or"> {props.t('Or')}  </span> */}
                                                        <span className="formbold-browse"> {props.t('Browse')} </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3 mt-3">
                                        {planSelectedInfo && planSelectedInfo.plans_payment_type.toLowerCase() !== 'one time' ?
                                            <div className="col-md-3">
                                                {formDataUploads.user_cheque ?
                                                    <img className="img-fluid uploads-member" alt="User-Image" src={formDataUploads.user_cheque} />
                                                    : <img className="img-fluid uploads-member" alt="User-Image" src={noImage} />}
                                                <div className="formbold-mb-5 formbold-file-input">
                                                    <label>
                                                        <input type="file" accept="image/png,image/jpg,image/jpeg" name="personal_photo" onChange={e => uploadMemberAttachment(e, 'member_cheque_update', 'cheque')} name="file" id="file" />
                                                        <div>
                                                            <span className="formbold-drop-file">{props.t('Cheque Photo')}  </span>
                                                            {/* <span className="formbold-or"> {props.t('Or')}  </span> */}
                                                            <span className="formbold-browse"> {props.t('Browse')} </span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            : ""}
                                    </div>


                                    {planSelectedInfo && planSelectedInfo.plans_payment_type.toLowerCase() !== 'one time' ?
                                        <div>
                                            <div className="head-text mb-4">{props.t('ACH/Direct Debit Information')}  </div>
                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">{props.t('Account Number')}<span className="text-danger">*</span></label>
                                                        <input className="form-control" maxLength="20" value={formNachInformation.account_number} name="account_number" label="Account Number" placeholder={props.t('Account Number')} type="text" onChange={e => updateNachFormData(e)}
                                                            onBlur={e => updateNachFormData(e)} />
                                                        <span className="text-danger">{errorFormNachInformation.account_number}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">{props.t('Bank Name')}<span className="text-danger">*</span></label>

                                                        <input className="form-control" maxLength="50" value={formNachInformation.bank_name} name="bank_name" label="Bank Name" placeholder={props.t('Bank Name')} type="text" onChange={e => updateNachFormData(e)}
                                                            onBlur={e => updateNachFormData(e)} />
                                                        <span className="text-danger">{errorFormNachInformation.bank_name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">{props.t('Bank Cheque Number')}<span className="text-danger">*</span></label>

                                                        <input className="form-control" maxLength="20" value={formNachInformation.bank_cheque_no} name="bank_cheque_no" label="Bank Cheque Number" placeholder={props.t('Bank Cheque Number')} type="text" onChange={e => updateNachFormData(e)}
                                                            onBlur={e => updateNachFormData(e)} />
                                                        <span className="text-danger">{errorFormNachInformation.bank_cheque_no}</span>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">{props.t('Bank Holder Name')}<span className="text-danger">*</span></label>

                                                        <input className="form-control" maxLength="50" value={formNachInformation.bank_holder_name} name="bank_holder_name" label="Bank Holder Name" placeholder={props.t('Bank Holder Name')} type="text" onChange={e => updateNachFormData(e)}
                                                            onBlur={e => updateNachFormData(e)} />
                                                        <span className="text-danger">{errorFormNachInformation.bank_holder_name}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                {/* <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">{props.t('Contribution Type')}<span className="text-danger">*</span></label>
                                                        <select className="form-control" name="payment_type" value={formNachInformation.payment_type} onChange={e => updateNachFormData(e)}>
                                                            {PaymentType.map((res, i) => {
                                                                return <option key={i} value={res.value}>{res.label}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">{props.t('Frequency')}<span className="text-danger">*</span></label>
                                                        <select className="form-control" name="frequency" value={formNachInformation.frequency} onChange={e => updateNachFormData(e)}>
                                                            {ContributionType.map((res, i) => {
                                                                return <option key={i} value={res.value}>{res.label}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">{props.t('IFSC Number')}<span className="text-danger">*</span></label>

                                                        <input className="form-control" maxLength="20" value={formNachInformation.ifsc} name="ifsc" label="IFSC Number" placeholder={props.t('IFSC Number')} type="text" onChange={e => updateNachFormData(e)}
                                                            onBlur={e => updateNachFormData(e)} />
                                                        <span className="text-danger">{errorFormNachInformation.ifsc}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">{props.t('MICR Number')}<span className="text-danger">*</span></label>

                                                        <input className="form-control" maxLength="20" value={formNachInformation.micr} name="micr" label="MICR Number" placeholder={props.t('MICR Number')} type="text" onChange={e => updateNachFormData(e)}
                                                            onBlur={e => updateNachFormData(e)} />
                                                        <span className="text-danger">{errorFormNachInformation.micr}</span>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        : ""}


                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="danger" className="">{props.t('Submit & Next')}</Button>
                                        <Button type="button" color="secondary" onClick={() => assignSelectedIndex(1)} className="">{props.t('Back')}</Button>
                                    </div>
                                </form>
                            </div>
                            :
                            <div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">{props.t('Contribution Type')}<span className="text-danger">*</span></label>
                                        <select className="form-control" name="typeofPayment" value={typeofPayment} onChange={e => setTypeofPayment(e.target.value)}>
                                            <option key="0" value="online">Online</option>
                                            <option key="1" value="offline">Offline</option>
                                        </select>
                                    </div>
                                </div>

                                {typeofPayment === 'offline' ?
                                    <form onSubmit={e => { handlePaymentSubmit(e) }}>
                                        <div className="row mb-3">{props.t('Offline Message')}</div>

                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="formbold-mb-5 formbold-file-input">
                                                    <label>
                                                        <input type="file" accept="image/png,image/jpg,image/jpeg" name="payment_receipt" onChange={e => uploadPaymentReceipt(e)} name="file" id="file" />
                                                        <div>
                                                            <span className="formbold-drop-file"> {props.t('Receipt File here')}  </span>
                                                            <span className="formbold-or"> {props.t('Or')} </span>
                                                            <span className="formbold-browse"> {props.t('Browse')}  </span>
                                                        </div>
                                                    </label>
                                                    <span className="text-danger">{errorFormPaymentFormData.payment_receipt}</span>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">{props.t('Amount')}<span className="text-danger">*</span></label>
                                                    <input className="form-control" disabled name="amount" value={planSelectedInfo.plans_amount} placeholder={props.t('Amount')} type="number" />
                                                    <span className="text-danger">{errorFormPaymentFormData.amount}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <Label>{props.t('Payment Date')}<span className="text-danger">*</span></Label>
                                                    <InputGroup>
                                                        <Flatpickr value={convertDate(formPaymentInformation.payment_date)} className="form-control d-block" placeholder="dd M,yyyy" name="payment_date"
                                                            onChange={e => updatePaymentDateSubmitForm(e)}
                                                            onBlur={e => updatePaymentDateSubmitForm(e)}
                                                            options={{ altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d" }} />
                                                    </InputGroup>
                                                    <span className="text-danger">{errorFormPaymentFormData.payment_date}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">{props.t('Contribution Type')}<span className="text-danger">*</span></label>
                                                    <select className="form-control" name="payment_type" value={formPaymentInformation.payment_type} onChange={e => updatePaymentFormData(e)}>
                                                        {PaymentType.map((res, i) => {
                                                            return <option key={i} value={res.value}>{res.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">{props.t('Contribution Note')}<span className="text-danger">*</span></label>
                                                    <input className="form-control" name="payment_note" value={formPaymentInformation.payment_note} placeholder={props.t('Contribution Note')} type="text" onChange={e => updatePaymentFormData(e)}
                                                        onBlur={e => updatePaymentFormData(e)} />
                                                    <span className="text-danger">{errorFormPaymentFormData.payment_note}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="danger" className="">{props.t('Add Payment')}</Button>
                                            <Button type="button" color="secondary" onClick={() => assignSelectedIndex(2)} className="">{props.t('Back')}</Button>
                                        </div>
                                    </form>
                                    : <form onSubmit={e => { handleOnlinePaymentSubmit(e) }}>

                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">{props.t('Amount')}<span className="text-danger">*</span></label>
                                                    <input className="form-control" name="amount" value={onlinePaymentAmounnt}
                                                        onChange={e => setOnlinePaymentAmount(e.target.value)}
                                                        onBlur={e => setOnlinePaymentAmount(e.target.value)} placeholder={props.t('Amount')} type="number" />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="danger" className="">{props.t('Pay Online')}</Button>
                                            <Button type="button" color="secondary" onClick={() => assignSelectedIndex(2)} className="">{props.t('Back')}</Button>
                                        </div>
                                    </form>
                                }

                                {paymentShowView && paymentShowView.length ?
                                    <PaymentView users={formData} payment={paymentShowView} />
                                    : ""}
                            </div>
                    }
                </Col>
            </Row >
        </React.Fragment >
    )
}


AddMember.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(AddMember))

