import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types"
import APICommon from '../../apiAuth';
import ToastMessage from "components/Common/toastar";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();


const ContactUs = props => {

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        mobileNo: "",
        message: "",
    });
    const [errorFormData, setErrorFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        mobileNo: "",
        message: "",
    });
    useEffect(() => {
        console.log(props)
    });

    function convertErrorMessage(e) {
        return e.replace(/_/g, " ");
    }

    function validate(name, value) {
        if (name === 'first_name' || name === 'last_name' || name === 'email' || name === 'message' || name === 'mobileNo') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                if (name === 'email' && !value.match(/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i)) {
                    return "Enter a valid email address";
                } else if (name === 'mobileNo' && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid mobile number";
                } else {
                    return "";
                }
            }
        }
    }

    const updateFormData = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
        setErrorFormData({
            ...errorFormData,
            [event.target.name]: validate(event.target.name, event.target.value)
        });
    }

    function handleSubmit(e) {
        e.preventDefault()
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            ToastMessage({ type: 'error', message: 'Invalid Captcha' })
            return;
        }
        let validationErrors = {};
        Object.keys(formData).forEach(name => {
            const error = validate(name, formData[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            setErrorFormData(validationErrors);
            return;
        }

        let data = { ...formData }
        APICommon.post('comman/add_contact', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message });
                let formresetData = {
                    first_name: "",
                    last_name: "",
                    email: "",
                    mobileNo: "",
                    message: "",
                }
                formData = formresetData;
                setFormData({ ...formresetData })
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Invalid Family Information' })
        });
    }

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    return (
        <div>
            <React.Fragment>
                <MetaTags>
                    <title>Contact Us</title>
                </MetaTags>
                <Container className="contact-page-container">
                    <Card>
                        <div className="contact-title-container">
                            <Row>
                                <Col lg="12">
                                    <div className="contact-us-title-container">
                                        <h3 className="text-white">{props.t('Contact Us')}</h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <CardBody className="contact-form-container">
                            <Row>
                                <Col lg="6">
                                    <div className="contact-us-col1-title"><h3>{props.t('Contact Us')}</h3>
                                    </div>
                                    <div className="contact-page-address d-flex-wrap">
                                        <i className="contact-page-footer-icon ti-pin"></i>
                                        <p>{props.t('Vishv Umiyadham – Ahmedabad')}<br />
                                            {props.t('Opp. Vaishnodevi Temple')} <br />
                                            {props.t('Sarkhej – Gandhinagar Highway')} <br />
                                            {props.t('Village Jaspur')} <br />
                                            {props.t('Ta Kalol, Gandhinagar')}<br />
                                            {props.t('Gujarat 382721')}
                                        </p>
                                    </div>
                                    <div className="contact-page-address-timing-wrapper">
                                        <div className="contact-page-address-timing-description d-flex-wrap"> <i className="contact-page-footer-icon ti-time"></i> <p>{props.t('10 AM to 7 PM')} </p></div>
                                    </div>
                                    <div className="contact-page-address-mobile-wrapper">
                                        <div className="contact-page-address-mobile-description d-flex-wrap"><i className="contact-page-footer-icon ti-mobile"></i> <p>+91-7202080 333/ 222</p></div>
                                    </div>
                                    <div className="contact-page-email-wrapper">
                                        <div className="contact-page-email-description d-flex-wrap"><i className="contact-page-footer-icon ti-time"></i><p>contact@vishvumiyafoundation.org</p></div>
                                    </div>
                                </Col>

                                <Col lg="6">
                                    <div className="contact-us-col1-title"><h3>{props.t('Contact Form')}</h3>
                                    </div>
                                    <form onSubmit={e => { handleSubmit(e) }}>

                                        <div className="contact-page-wrapper d-flex-wrap">
                                            <div className="row w-100 col-md-12">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <input className="form-control" maxLength="70" name="first_name" label="first_name" onChange={e => updateFormData(e)}
                                                            onBlur={e => updateFormData(e)} placeholder="First Name" type="text" />
                                                        <span className="text-danger">{errorFormData.first_name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <input className="form-control" maxLength="70" name="last_name" label="last_name" onChange={e => updateFormData(e)}
                                                            onBlur={e => updateFormData(e)} placeholder="Last Name" type="text" />
                                                        <span className="text-danger">{errorFormData.last_name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row w-100 col-md-12">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <input className="form-control" maxLength="70" name="email" label="Email" onChange={e => updateFormData(e)}
                                                            onBlur={e => updateFormData(e)} placeholder="Email" type="text" />
                                                        <span className="text-danger">{errorFormData.email}</span>

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <input className="form-control" maxLength="70" name="mobileNo" label="mobileNo" onChange={e => updateFormData(e)}
                                                            onBlur={e => updateFormData(e)} placeholder="Mobile Number" type="text" />
                                                        <span className="text-danger">{errorFormData.mobileNo}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <Row className="w-100">
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <textarea className="form-control" maxLength="70" name="message" label="message" onChange={e => updateFormData(e)}
                                                            onBlur={e => updateFormData(e)} placeholder="Message" type="text" ></textarea>
                                                        <span className="text-danger">{errorFormData.message}</span>

                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="w-100">
                                                <ReCAPTCHA ref={recaptchaRef} sitekey={"6Lf0yi8pAAAAAAK5CPmPkzr4-nB6ei7Do3-TF1aC"} onChange={onChange} />,
                                            </Row>
                                            <div className="row w-100 col-md-12">
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <button type="submit" className="btn btn-danger w-md waves-effect waves-light"> Submit </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </React.Fragment>
        </div>
    )

}

ContactUs.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(ContactUs))
