import React from "react"
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"


//Pages
import Pages404 from "pages/Utility/pages-404"
import Home from "pages/Home/home";
import contactUs from "pages/Contact-Us/contactUs";
import UserHome from "pages/User-Home/UserHome";
import AddMember from "pages/User-Member/addMember";
import EditDivangat from "pages/User-Member/editDivangat";

import memberOuter from "pages/User-Member/memberOuter";
import EditMember from "pages/User-Member/editMember";
import ForgetPassword from "pages/Authentication/ForgetPassword";
import paymentResponse from "pages/User-Member/paymentResponse";

const userRoutes = [
  { path: "/users/home", component: Home },
  { path: "/users/user-home", component: UserHome },
  { path: "/users/member", component: memberOuter },
  { path: "/users/edit-member/:id", component: EditMember },
  { path: "/users/edit-divangat/:id", component: EditDivangat },
  { path: "/users/view-member/:id", component: EditMember },
  { path: "/users/contactus", component: contactUs },
  { path: "/users/paymentresponse/:id", component: paymentResponse },

  { path: "/", exact: true, component: () => <Redirect to="/users/home" /> },
  // this route should be at the end of all other routes
]

const authRoutes = [
  { path: "/users/logout", component: Logout },
  { path: "/users/login", component: Login },
  { path: "/users/forget-password", component: ForgetPassword },
  { path: "/users/404", component: Pages404 },
]

export { userRoutes, authRoutes }
