import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"

//i18n
import i18n from "../../i18n"

const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("")
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    setSelectedLang(currentLanguage || 'eng')
  }, [])

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    setSelectedLang(lang)
  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <a className={'nav-language-link ' + (selectedLang === 'guj' ? "active" : "")} href="#!"   onClick={() => changeLanguageAction('guj')}> Guj </a>
      {/* <a className={'nav-language-link ' + (selectedLang === 'hin' ? "active" : "")} href="#!" onClick={() => changeLanguageAction('hin')}> Hin </a> */}
      <a className={'nav-language-link ' + (selectedLang === 'eng' ? "active" : "")} href="#!" onClick={() => changeLanguageAction('eng')}> Eng </a>
    </>
  )
}

export default withTranslation()(LanguageDropdown)
