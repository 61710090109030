import React from "react"
import { Container, Row, Col } from "reactstrap"
import logosmImg from "../../assets/images/umachattra-logo.png";
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";

const Footer = props => {
  return (
    <React.Fragment>
      <footer className="footer-user footer-wrapper">
        <div className="d-flex-wrap">
          <div className="container">
            <Row className="container">
              <div className="col-md-4">
                <div className="gutters-bottom-wrapper">
                  <img src={logosmImg} alt="ummachhatra-logo" className="umachattra-logo" height="150" />
                </div>
                <div className="copyright-text-footer gutters-bottom-wrapper">
                  <div className="copyright-text-text-inner">© Copyright 2023 Vishv Umiya Foundation. Terms &amp; Condition.</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="quick-link-container">
                  <div className="quick-link-inner"><h5>Quick Links</h5>
                  </div>
                </div>
                <div className="quick-link-sub-menu-link-wrapper">
                  <nav className="quick-link-nav">
                    <ul id="menu-header-menu" className="et-menu nav downwards">
                      <li id="menu-item-4237" className="quick-link-inner-list" ><a className="quick-link-text" href="https://vishvumiyafoundation.org/staging/about-us/">{props.t("About Us")}</a></li>
                      {/* <li id="menu-item-527" className="quick-link-inner-list" ><a href="https://vishvumiyafoundation.org/staging/contact-us/" className="quick-link-text">{props.t("Events")}</a></li>
                      <li id="menu-item-2240" className="quick-link-inner-list" ><a href="https://vishvumiyafoundation.org/staging/press-release/" className="quick-link-text">{props.t("Projects")}</a></li>
                      <li id="menu-item-527" className="quick-link-inner-list" ><a href="https://vishvumiyafoundation.org/staging/contact-us/" className="quick-link-text">{props.t("Umashrusti")}</a></li> */}
                      <li id="menu-item-4237" className="quick-link-inner-list" ><a className="quick-link-text" href="https://vishvumiyafoundation.org/staging/privacy-policy/">{props.t("Privacy Policy")}</a></li>
                      <li id="menu-item-4237" className="quick-link-inner-list" ><a className="quick-link-text" href="https://vishvumiyafoundation.org/staging/terms-and-conditions/">{props.t("Terms And Conditions")}</a></li>
                      <li id="menu-item-4237" className="quick-link-inner-list" ><a className="quick-link-text" href="https://vishvumiyafoundation.org/staging/about-us/">{props.t("UmaChattra Plan")}</a></li>

                      {/* <a className="menu-item-responsive" href="https://vishvumiyafoundation.org/staging/privacy-policy/"> {props.t("Privacy Policy")} </a>
                      <a className="menu-item-responsive" href="https://vishvumiyafoundation.org/staging/terms-and-conditions/"> {props.t("Terms And Conditions")} </a>
                      <a className="menu-item-responsive"> {props.t("UmaChattra Plan")} </a> */}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-us-container">
                  <div className="contact-us-text-inner"><h5>{props.t('Contact Us')}</h5></div>
                </div>
                <div className="contact-us-address-wrapper">
                  <div className="contact-us-address-description d-flex-wrap">
                    <i className="footer-icon ti-pin"></i> <p>{props.t('Vishv Umiyadham – Ahmedabad')}<br />
                      {props.t('Opp. Vaishnodevi Temple')}<br />
                      {props.t('Sarkhej – Gandhinagar Highway')}<br />
                      {props.t('Village Jaspur')}<br />
                      {props.t('Ta Kalol, Gandhinagar')}<br />
                      {props.t('Gujarat 382721')}
                    </p>
                  </div>
                </div>
                <div className="contact-us-timing-wrapper">
                  <div className="contact-us-timing-description d-flex-wrap"> <i className="footer-icon ti-time"></i> <p>{props.t('10 AM to 7 PM')}</p></div>
                </div>
                <div className="contact-us-mobile-wrapper">
                  <div className="contact-us-mobile-description d-flex-wrap"><i className="footer-icon ti-mobile"></i> <p>+91-7202080 333/ 222</p></div>
                </div>
                <div className="contact-us-email-wrapper">
                  <div className="contact-us-email-description d-flex-wrap"><i className="footer-icon ti-time"></i><p>contact@vishvumiyafoundation.org</p></div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </footer>
    </React.Fragment >
  )
}


Footer.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Footer))
