import React, { useEffect, useState } from "react";
import Slidewithfade from "./CarouselTypes/slidewithfade"
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, CardTitle, Container, CardImg, Modal } from "reactstrap";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types"
import vishvUmiya from "../../assets/images/umachattra-logo.png"
import sideBanner from "../../assets/images/side-banner.png"
import brochure from "../../assets/images/project_broucher.png"
import ecsPDF from "../../assets/images/project_ecs.png"
import pdf from "../../assets/images/project_pdf.png"
import visvProject from "../../assets/images/visv-umiya-foundation.png"
import matrimonial from "../../assets/images/project_matrimoni.jpg"
import events from "../../assets/images/project_event.jpg"
import Program from "pages/Home/program";
import { Link } from "react-router-dom"
import API from '../../apiAuth';

const Home = props => {

    let [enableMemberFormModal, setEnableMemberFormModal] = useState(false);
    let [isOpen, setIsOpen] = useState(false);
    let [countMember, setCountMember] = useState(false);

    useEffect(() => {
        console.log(props)
        if (!localStorage.getItem("I18N_LANGUAGE"))
            localStorage.setItem("I18N_LANGUAGE", 'eng');

        if (!localStorage.getItem("auth-token") && !isOpen) {
            setEnableMemberFormModal(true)
        }
        showCount()
    })

    function modalOpen() {
        setEnableMemberFormModal(false);
        setIsOpen(true);
    }

    // Validate OTP
    function showCount() {
        API.get('comman/get_report', {}).then(res => {
            if (res.data && res.data.status) {
                setCountMember(res.data.data.html)
            } else {
                ToastMessage({ type: 'error', message: 'Authentication Failed' })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: error.message })
        });
    }

    return (
        <div>
            <React.Fragment>
                <MetaTags>
                    <title>Home</title>
                </MetaTags>
                <Row>
                    <Col lg="12">
                        <Slidewithfade />
                    </Col>
                </Row>
                <Modal
                    size="lg"
                    isOpen={enableMemberFormModal}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myExtraLargeModalLabel"
                        >
                            ઉમાછત્ર
                    </h5>
                        <button onClick={() => { modalOpen() }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>સ્નેહીજનો, ઉગવું કે આથમવું સૃષ્ટિનો ક્રમ છે. એમ જન્મ કે મરણ કુદરતી ક્રમ છે પણ જે યુવાવયે જ
આથમી જાય તો તે કુંટુંબની આર્થિક સ્થિતિ કથળતી હોય છે. વ્યક્તિની ખોટ પૂરી શકાતી નથી પણ પાટીદાર
કુંટુંબ માટે વિશ્વ ઉમિયાધામ દ્વારા ₹10 લાખની સહાય એ મા ઉમિયાનાં આશીર્વાદનું છત્ર છે. માટે
સૌએ બે માંથી એક વિકલ્પ પસંદ કરી ઉમાછત્રમાં જોડાવું ખાસ જરૂરી છે.</div><br />
                        <div>1. પ્રારંભિક માત્ર ₹૪૦૦૦ બાદ દર વર્ષે પોતાની 55 વય સુધી ₹૨૨૦૦ આસપાસ વાર્ષિક ફાળો આપવો રહેશે.
                        </div>
                        <div>અથવા</div>
                        <div>
                            2. દર વર્ષે ઝંઝટને બદલે અને જે સક્ષમ છે તેણે 55વય સુધીના એક વખત ₹૩૧૦૦૦ ફાળો આપવાનો રહેશે.
                        </div>
                        <br />
                        <div>
                            આપ અને અન્યને પણ ઉમાછત્ર કવચ અપાવીને તેનાં કુંટુંબને ₹10 લાખની સહાય મા ઉમાનું છત્ર બની
                            રહેશે. વિશ્વ ઉમિયાધામ થકી મા ઉમિયાના આર્શીવાદરૂપ ₹10 લાખની માતબર રકમનો સધિયારો બદલ આપને પણ આંગળી ચિંધ્યાનું પુણ્ય થશે.
                        </div>
                        <br /><br />
                        <div className="col-md-12 d-flex auth-section-modal">
                            <div className="navbar-link-login margin-login"><Link to="/users/login">આપની વિગત જોવા કે કોઈ ભૂલ સુધારવા અહીં ક્લિક કરવું</Link></div>
                            <div className="navbar-link-login margin-login"><Link to="/users/login">ઉમાછત્રમાં સભ્ય થવા અહીં ક્લિક કરવું</Link></div>
                        </div>
                    </div>
                </Modal>
                <div className="counter home-section section-1 container">
                
                    <div className="row">
                        <div dangerouslySetInnerHTML={{ __html: countMember }}></div>
                    </div>
                </div>
                <div className="container-fluid">
                    <Container className="home-section section-1">
                        <Row>
                            <Col lg="6">
                                <div className="title-container">
                                    <h4>{props.t('WELCOME')}</h4>
                                    <h3>{props.t('VISHV UMIYA FOUNDATION')}</h3>
                                </div>
                                <div className="text-left-container">
                                    <p>{props.t('ABOUT US Content')}</p>
                                </div>

                                <div className="btn-container">
                                    <button className="btn btn-danger w-md waves-effect waves-light">{props.t('Read More...')}</button>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="img-container-card">
                                    <img src={sideBanner} className="side-img-visv" alt="side-banner" />
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Program />
                </div>

            </React.Fragment>
        </div >
    )

}

Home.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(Home))
